import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Grid, InputAdornment, Stack } from '@mui/material';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import {
  PrejudiceFormValeur,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { useTranslation } from 'react-i18next';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { ResourcesButton } from 'src/components/resources/ResourcesButton';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import i18next from 'i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaFormValeur: yup.ObjectSchema<PrejudiceFormValeur> =
  validationSchemaPrejudiceBase.shape({
    montant: yup.number().min(0).required(),
    numerosPieces: validationSchemaNumeroPieces,
    argument: yup.string().optional(),
    partResponsabilite: yup
      .number()
      .optional()
      .min(0)
      .max(100)
      .transform((value) => value / 100),
  });

type Props = PrejudiceFormProps<PrejudiceFormValeur>;

const displayPartResponsabiliteField = (prejudiceType: PrejudiceType) =>
  Boolean(prejudiceType === 'PREJUDICE_IMPREPARATION_MEDICALE');

export const valeurDefaultValues = ({
  values,
  procedurePartResponsabilite,
  prejudiceType,
  multiplyPartResponsabilite,
}: {
  values?: PrejudiceFormValeur;
  procedurePartResponsabilite: number;
  prejudiceType: PrejudiceType;
  multiplyPartResponsabilite: boolean;
}): PrejudiceFormValeur => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  argument: values?.argument || '',
  montant: values?.montant || 0,
  numerosPieces: values?.numerosPieces || {
    rows: [],
  },
  partResponsabilite:
    (!displayPartResponsabiliteField(prejudiceType)
      ? procedurePartResponsabilite
      : values?.partResponsabilite || values?.partResponsabilite === 0
        ? values?.partResponsabilite
        : procedurePartResponsabilite) * (multiplyPartResponsabilite ? 100 : 1),
});

export const FormValeur: React.FC<Props> = ({
  values,
  partResponsabilite: procedurePartResponsabilite,
  displayTotalPartResponsabilite,
  prejudiceType,
  allNumerosPieces,
  ...props
}) => {
  const useFormReturn = useForm<PrejudiceFormValeur>({
    defaultValues: valeurDefaultValues({
      values,
      procedurePartResponsabilite,
      prejudiceType,
      multiplyPartResponsabilite: true,
    }),
    mode: 'onTouched',
    resolver: yupResolver(validationSchemaFormValeur),
  });
  const { control } = useFormReturn;

  const { t } = useTranslation();

  const displayBareme = Boolean(
    prejudiceType === 'PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION',
  );

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormValeur>
      {...props}
      prejudiceType={prejudiceType}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack spacing={2} sx={{ padding: 1 }} alignItems="start">
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <FormNumeroPieceDialog
            control={control}
            name="numerosPieces"
            displayNumeroPieceText={true}
            allOtherNumeroPieces={allNumerosPieces}
          />
          {displayPartResponsabiliteField(prejudiceType) && (
            <ComputedTextFieldForm
              control={control}
              name="partResponsabilite"
              label={t('partResponsabilite.label')}
              watchFields={[]}
              compute={() => procedurePartResponsabilite * 100}
              InputLabelProps={{
                shrink: true,
                sx: { color: 'info.light' },
              }}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2, min: 0, max: 100 },
                  suffix: '%',
                } as IMaskNumberProps,
                sx: {
                  color: 'info.light',
                  borderColor: 'info.light',
                  ' .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'info.light',
                  },
                },
              }}
              sx={{ minWidth: '268px' }}
            />
          )}
          <TextFieldForm
            control={control}
            name="montant"
            label={t(
              'prejudice.prejudicesFormTypes.VALEUR.fields.montant.label',
            )}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                suffix: '€',
                numberMask: { scale: 2 },
              } as IMaskNumberProps,
            }}
            sx={{ minWidth: '268px' }}
          />
          {displayTotalPartResponsabilite && (
            <ComputedPropsForm
              control={control}
              watchFields={['montant', 'partResponsabilite']}
              compute={([montant, partResponsabilite]) => {
                const partResponsabiliteValue = partResponsabilite
                  ? partResponsabilite / 100
                  : procedurePartResponsabilite;
                return {
                  props: {
                    totalPartResponsabilite:
                      montant * (partResponsabiliteValue || 0),
                    total: montant,
                    partResponsabilite: partResponsabiliteValue,
                  },
                };
              }}
              render={({
                totalPartResponsabilite,
                total,
                partResponsabilite,
              }) => (
                <Stack alignSelf="end" maxWidth="400px">
                  <TotalDeficit
                    totalPartResponsabilite={totalPartResponsabilite}
                    totalMontantTotal={total}
                    displayPartResponsabilite={displayTotalPartResponsabilite}
                    partResponsabilite={partResponsabilite}
                  />
                </Stack>
              )}
            />
          )}
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={props.victime} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              {displayBareme && <ResourcesButton baremeType="Scolaire" />}
            </Grid>
          </Grid>
        </Stack>
      )}
    />
  );
};
