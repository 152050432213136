import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputAdornment, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceType,
  RevenuAnnuelEspere,
  RevenuAnnuelEspereRow,
  unites,
} from '../../../types/prejudice.type';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { useTranslation } from 'react-i18next';
import { Victime } from 'src/types/victime.type';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { Procedure } from 'src/types/procedure.type';
import {
  getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  getDefaultPerteDeGainsProfessionnelsRow,
} from './NewFormPerteGainsProfessionnelsFuturs';
import {
  validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret,
  validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels,
} from './NewFormPerteGainsProfessionnelsActuel';
import { StepManagerSaisieDirecte } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/StepManagerSaisieDirecte';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import i18next from 'i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { sortBy } from 'lodash';

const validationSchemaRevenuEspereRow =
  (): yup.ObjectSchema<RevenuAnnuelEspereRow> =>
    yup.object({
      numerosPieces: validationSchemaNumeroPieces,
      revenuEspere: yup.number().required().min(0),
      unite: yup.string().oneOf(unites).required(),
      coefficientPerteDeChance: yup.number().required().min(0).max(100),
      perteDeChance: yup.number().required().min(0),
    });

const validationSchemaRevenuEspere = (): yup.ObjectSchema<RevenuAnnuelEspere> =>
  yup.object({
    rows: yup.array().of(validationSchemaRevenuEspereRow()).defined(),
    total: yup.number().optional().defined(),
  });

export const validationSchemaPerteGainsProfessionnelsActuelSaisieDirecte = ({
  victime,
  procedure,
  prejudiceType,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte> =>
  validationSchemaPrejudiceBase.shape({
    revenuEspere: validationSchemaRevenuEspere(),
    perteDeGainsProfessionnels:
      validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels(
        { victime, procedure, prejudiceType, isPGPF: false },
      ),
    indemnitesJournalieresPercuesPendantLaPeriodeDArret:
      validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret(
        { victime, procedure, prejudiceType },
      ),

    formType: yup.string().oneOf(['SAISIE_DIRECTE']).required(),
    revalorisationCoefficientsType: yup
      .string()
      .oneOf(['annuel', 'smic'])
      .required(),
    total: yup.number().optional().defined(),
  });

export const getDefaultRevenuEspereRow = (): RevenuAnnuelEspereRow => ({
  revenuEspere: 0,
  unite: '',
  coefficientPerteDeChance: 100,
  perteDeChance: 0,
  numerosPieces: { rows: [] },
});

export const PGPASaisieDirecteDefaultValues = ({
  values,
}: {
  values?: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
}): PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  revenuEspere: {
    rows:
      values?.revenuEspere?.rows.map((row) => ({
        ...getDefaultRevenuEspereRow(),
        ...row,
      })) || [],
    total: values?.revenuEspere?.total || 0,
  },
  perteDeGainsProfessionnels: {
    rows:
      sortBy(
        values?.perteDeGainsProfessionnels?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultPerteDeGainsProfessionnelsRow(),
        ...row,
      })) || [],
    total: values?.perteDeGainsProfessionnels?.total || 0,
    revenusPercusTotal:
      values?.perteDeGainsProfessionnels?.revenusPercusTotal || 0,
    revenuReferenceTotal:
      values?.perteDeGainsProfessionnels?.revenuReferenceTotal || 0,
  },
  indemnitesJournalieresPercuesPendantLaPeriodeDArret: {
    rows:
      sortBy(
        values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow(),
        ...row,
      })) || [],
    csgRdsTotal:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.csgRdsTotal || null,
    indemnitesJournalieresPercuesNet:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.indemnitesJournalieresPercuesNet || null,
  },
  formType: values?.formType || 'SAISIE_DIRECTE',
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'annuel',
  total: values?.total || 0,
});
type Props =
  PrejudiceFormProps<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;

export const FormPerteGainsProfessionnelsActuelSaisieDirecte: React.FC<
  Props
> = ({
  victime,
  procedure,
  dommages,
  values,
  monetaryErosions,
  onPrevious,
  dateConsolidation,
  dateLiquidation,
  allNumerosPieces,
  ...props
}) => {
  const { t } = useTranslation();
  const useFormReturn =
    useForm<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>({
      defaultValues: PGPASaisieDirecteDefaultValues({
        values,
      }),
      resolver: yupResolver(
        validationSchemaPerteGainsProfessionnelsActuelSaisieDirecte({
          victime,
          procedure,
          prejudiceType: props.prejudiceType,
        }),
      ),
    });
  const { control, trigger, handleSubmit, getValues } = useFormReturn;

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={({ setAdditionalTitle }) => (
        <Stack direction="column" spacing={4}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <StepManagerSaisieDirecte
            setAdditionalTitle={setAdditionalTitle}
            procedure={procedure}
            dommage={dommages?.[0]}
            prejudiceType={props.prejudiceType}
            victime={victime}
            control={control}
            trigger={trigger}
            values={values}
            onClose={props.onClose}
            monetaryErosions={monetaryErosions}
            onPrevious={onPrevious ? onPrevious : undefined}
            handleSubmit={handleSubmit}
            dateConsolidation={dateConsolidation}
            dateLiquidation={dateLiquidation}
            tauxIPP={null}
            allNumerosPieces={allNumerosPieces}
            user={props.user}
            getValues={getValues}
          />
        </Stack>
      )}
    />
  );
};
