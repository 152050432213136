import i18next from 'i18next';
import { fDecimalNumber } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { DepenseFrequence, Unite } from 'src/types/prejudice.type';

export const getPeriodePrint = ({
  dateDebut,
  dateFin,
  duree,
  unite = 'jour',
}: {
  dateDebut: Date | string | undefined | null;
  dateFin: Date | string | undefined | null;
  duree?: number | undefined | null;
  unite?: Unite;
}): string => {
  if (dateDebut && dateFin && duree && unite) {
    return i18next.t('print.prejudice.periode.debutFinAndDuree', {
      dateDebut: fDate(dateDebut),
      dateFin: fDate(dateFin),
      duree: fDecimalNumber(duree),
      unite,
      roundedDuree: fDecimalNumber(Math.floor(duree)),
    });
  } else if (dateDebut && dateFin) {
    return i18next.t('print.prejudice.periode.debutFin', {
      dateDebut: fDate(dateDebut),
      dateFin: fDate(dateFin),
    });
  } else if (dateDebut) {
    return i18next.t('print.prejudice.periode.debut', {
      dateDebut: fDate(dateDebut),
    });
  } else if (dateFin) {
    return i18next.t('print.prejudice.periode.fin', {
      dateFin: fDate(dateFin),
    });
  }
  return '';
};
const DepenseFrequenceToUnite: Record<DepenseFrequence, Unite | null> = {
  viagere: 'annee',
  quotidien: 'jour',
  hebdomadaire: 'semaine',
  mensuel: 'mois',
  annuel: 'annee',
  // eslint-disable-next-line camelcase
  non_renseigne: null,
};
export const getPeriodePrintByFrequence = ({
  dateDebut,
  dateFin,
  dureeDansUniteChoisie,
  frequenceMontant,
}: {
  dateDebut: Date | string | undefined | null;
  dateFin: Date | string | undefined | null;
  dureeDansUniteChoisie?: number | undefined | null;
  frequenceMontant?: DepenseFrequence;
}): string => {
  const unite = frequenceMontant
    ? DepenseFrequenceToUnite[frequenceMontant] ?? undefined
    : undefined;
  return getPeriodePrint({
    dateDebut,
    dateFin,
    duree: dureeDansUniteChoisie,
    unite,
  });
};
