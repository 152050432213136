import {
  AlignmentType,
  BorderStyle,
  HeightRule,
  Table,
  TableRow,
  WidthType,
} from 'docx';
import {
  getHeaderTableCell,
  getTableRow,
  getValueTableCell,
} from './docxFunctions';

export const simpleVerticalTablePrint = (
  values: (
    | {
        label: string;
        value: string;
        bold?: boolean;
      }
    | {
        title: string;
        bold?: boolean;
      }
    | {
        row: TableRow;
      }
  )[],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  const totalWidth = 9000;
  const labelColumnWidth = Math.floor(totalWidth * 0.3);
  const valueColumnWidth = Math.floor(totalWidth * 0.7);

  return new Table({
    alignment: alignment ?? AlignmentType.START,
    width: {
      size: totalWidth,
      type: WidthType.DXA,
    },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1 },
      bottom: { style: BorderStyle.SINGLE, size: 1 },
      left: { style: BorderStyle.SINGLE, size: 1 },
      right: { style: BorderStyle.SINGLE, size: 1 },
      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
    },
    columnWidths: [labelColumnWidth, valueColumnWidth],
    layout: 'fixed',
    rows: values.map((value) => {
      if ('title' in value) {
        return getTableRow({
          children: [
            getHeaderTableCell(
              value.title,
              totalWidth,
              {
                alignment: AlignmentType.CENTER,
              },
              {
                columnSpan: 2,
              },
              value.bold !== undefined
                ? {
                    bold: value.bold,
                  }
                : undefined,
            ),
          ],
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
        });
      } else if ('label' in value) {
        return getTableRow({
          children: [
            value.bold
              ? getHeaderTableCell(value.label, labelColumnWidth)
              : getHeaderTableCell(
                  value.label,
                  labelColumnWidth,
                  undefined,
                  undefined,
                  {
                    bold: false,
                  },
                ),
            getValueTableCell(value.value, valueColumnWidth),
          ],
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
        });
      } else {
        return value.row;
      }
    }),
  });
};
