import {
  AlignmentType,
  HeightRule,
  Paragraph,
  Table,
  TableCell,
  TableOfContents,
  TableRow,
} from 'docx';
import { fDecimalNumber } from 'src/helpers/formatNumber';
import { getEmptyLine, getTableCell, getTextRun } from '../docxFunctions';

export interface FormTableRow<
  O extends Partial<Record<K, V>>,
  K extends keyof O & string,
  V,
> {
  name: K;
  render?: (rowValues: O) => TableCell | string | null;
  headerLabel?: TableCell | string;
  children?: {
    render?: (rowValues: O) => TableCell | string | null;
    headerLabel?: TableCell | string;
  }[];
}

export const displayValueCells = <
  O extends Partial<Record<K, V>>,
  K extends keyof O & string,
  V,
>(
  columns: O[],
  row: FormTableRow<O, K, V>,
): TableCell[] => {
  return columns.map((column) => {
    const rendered = row?.render?.(column);
    if (rendered && typeof rendered !== 'string') {
      return rendered;
    }
    const columnValue: O[K] | string | undefined =
      rendered || rendered === ''
        ? rendered
        : column?.[row.name]
          ? column[row.name]
          : '';
    if (typeof columnValue === 'string' || typeof columnValue === 'number') {
      return getTableCell({
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: getTextRun({
              text:
                typeof columnValue === 'string'
                  ? columnValue
                  : fDecimalNumber(columnValue),
            }),
          }),
        ],
      });
    } else {
      return getTableCell({
        children: [
          new Paragraph({
            children: getTextRun({ text: '' }),
          }),
        ],
      });
    }
  });
};

export const displayHeaderCell = <
  O extends Partial<Record<K, V>>,
  K extends keyof O & string,
  V,
>(
  row: FormTableRow<O, K, V>,
): TableCell => {
  const headerLabel = row.headerLabel || row.name;
  if (typeof headerLabel === 'string') {
    return getTableCell({
      children: [
        new Paragraph({
          children: getTextRun({
            text: headerLabel ?? '',
            size: 20,
            bold: true,
          }),
        }),
      ],
    });
  } else {
    return headerLabel;
  }
};

const splitColumnsIntoChunks = <
  O extends Partial<Record<K, V>>,
  K extends keyof O & string,
  V,
>(
  columns: O[],
  numberOfColumnsPerChunk = 6,
): O[][] => {
  const chunks: O[][] = [];
  for (let i = 0; i < columns.length; i += numberOfColumnsPerChunk) {
    chunks.push(columns.slice(i, i + numberOfColumnsPerChunk));
  }
  return chunks;
};

export const prejudiceFormTable = <
  O extends Partial<Record<K, V>>,
  K extends keyof O & string,
  V,
>(
  columns: O[],
  rows: FormTableRow<O, K, V>[],
): (Paragraph | Table | TableOfContents)[] =>
  splitColumnsIntoChunks(columns).reduce(
    (accumulator: (Paragraph | Table | TableOfContents)[], chunk) => {
      if (accumulator.length > 0) {
        accumulator.push(getEmptyLine(2));
      }
      accumulator.push(
        new Table({
          rows: rows.reduce((accumulator: TableRow[], row) => {
            if (row.children) {
              accumulator.push(
                ...row.children.map(
                  (child) =>
                    new TableRow({
                      height: {
                        value: 400,
                        rule: HeightRule.ATLEAST,
                      },
                      children: [
                        displayHeaderCell({ name: row.name, ...child }),
                        ...displayValueCells(chunk, {
                          name: row.name,
                          ...child,
                        }),
                      ],
                    }),
                ),
              );
            } else {
              accumulator.push(
                new TableRow({
                  height: {
                    value: 400,
                    rule: HeightRule.ATLEAST,
                  },
                  children: [
                    displayHeaderCell(row),
                    ...displayValueCells(chunk, row),
                  ],
                }),
              );
            }
            return accumulator;
          }, []),
        }),
      );
      return accumulator;
    },
    [],
  );
