import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Grid, InputAdornment, Stack, Tabs } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PerteDeGainsProfessionnelsRow,
  PertesDeGainsProfessionnelsPGPF,
  PrejudiceType,
  RevenuActiviteAnnuelDeReferenceRow,
} from '../../../types/prejudice.type';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { NewStepManager } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/NewStepManager';
import {
  getCapitalisationBaremes,
  getShouldNotDisplayCapitalisation,
} from '../../../helpers/prejudices/capitalisation';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from '../../basic/CalculsBox';
import { TotalNewPerteGainsFuturs } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/TotalNewPerteGainsFuturs';
import { intersection, omit, sortBy } from 'lodash';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  defaultPerteDeChanceDeGainProfessionnelRow,
  validationSchemaNewFormPerteGainsProfessionnelsActuel,
  validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret,
  validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArretRow,
  validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels,
  validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow,
  validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReference,
  validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReferenceRow,
} from './NewFormPerteGainsProfessionnelsActuel';
import { dateString } from 'src/helpers/yup';
import {
  getDefaultMinAndMaxDate,
  validationSchemaPrejudiceBase,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
  validationSchemaTiersPayeursCapitalisationObject,
} from 'src/constants/prejudice/validation';
import { PrejudiceTab } from '../PrejudiceTab';
import { TotalCapitalisation } from '../prejudiceFormComponents/Capitalisation/TotalCapitalisation';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import i18next from 'i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaNewFormPerteGainsProfessionnelsFutursPerteDeGainsProfessionnels =
  ({
    victime,
    procedure,
    prejudiceType,
  }: {
    victime: Victime;
    procedure: Procedure;
    prejudiceType: PrejudiceType;
  }): yup.ObjectSchema<PertesDeGainsProfessionnelsPGPF> =>
    validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels(
      {
        victime,
        procedure,
        prejudiceType,
        isPGPF: true,
      },
    ).shape({
      annualisation: yup.number().required().min(0),
    });

export const validationSchemaNewFormPerteGainsProfessionnelsFuturs = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<NewPrejudiceFormPerteGainProfessionnelsFuturs> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .concat(
      validationSchemaNewFormPerteGainsProfessionnelsActuel({
        victime,
        procedure,
        prejudiceType,
      }),
    )
    .shape({
      revenuActiviteAnnuelDeReference:
        validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReference(
          {
            victime,
            procedure,
          },
        ).shape({
          rows: yup
            .array()
            .defined()
            .of(
              validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReferenceRow(
                {
                  victime,
                  procedure,
                  isPGPF: true,
                },
              ),
            ),
        }),
      perteDeGainsProfessionnels:
        validationSchemaNewFormPerteGainsProfessionnelsFutursPerteDeGainsProfessionnels(
          {
            victime,
            procedure,
            prejudiceType,
          },
        ).shape({
          rows: yup
            .array()
            .defined()
            .of(
              validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow(
                {
                  victime,
                  procedure,
                  prejudiceType,
                  isPGPF: true,
                },
              ).shape({
                dateDebut: yup
                  .string()
                  .isDateString()
                  .optional()
                  .nullable()
                  .defined()
                  .minDate(undefined, minDate.message, minDate.date)
                  .maxDate(undefined, maxDate.message, maxDate.date),
              }),
            ),
        }),
      indemnitesJournalieresPercuesPendantLaPeriodeDArret:
        validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret(
          {
            victime,
            procedure,
            prejudiceType,
          },
        ).shape({
          rows: yup
            .array()
            .defined()
            .of(
              validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArretRow(
                {
                  victime,
                  procedure,
                  prejudiceType,
                },
              ).shape({
                dateDebut: dateString()
                  .optional()
                  .nullable()
                  .defined()
                  .minDate(undefined, minDate.message, minDate.date)
                  .maxDate(undefined, maxDate.message, maxDate.date),
              }),
            ),
        }),
      typeBareme: yup.string().optional().nullable(),
      ageDernierArrerage: yup.lazy((value) =>
        value === ''
          ? (yup
              .string()
              .defined()
              .transform(() => null) as unknown as yup.NumberSchema<
              number,
              yup.AnyObject,
              undefined,
              ''
            >)
          : yup.number().defined().nullable().min(0),
      ),
      isLastArrerageViager: yup.boolean().optional(),
      baremeCapitalisation: yup.string().optional(),
      victimeSommeACapitaliser: yup.number().required().min(0),
      prixEuroRente: yup.number().nullable().defined().min(0),
      renteCapitalisee: yup.number().required().min(0),
      capitalisationTiersPayeurs: yup.object(
        omit(validationSchemaTiersPayeursCapitalisationObject(), [
          'montantCapitalise',
        ]),
      ),
      tiersPayeursTotalCapitalise: yup.number().required().min(0),
      ageDateAttribution: yup.number().optional(),
      capitalConstitutifRente: yup.number().required(),

      commentaires: yup.string().optional().defined(),
      PGPFReliquat: yup.number().nullable().defined(),
      activatePGPFReliquat: yup.boolean().nullable().defined(),
      total: yup.number().required(),
      formType: yup.string().oneOf(['REVENUS']).required(),
      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['annuel', 'smic'])
        .required(),
      enableCapitalisationDifferee: yup.boolean().optional(),
    });
};

export const defaultRevenuActiviteAnnuelDeReferenceRow: RevenuActiviteAnnuelDeReferenceRow =
  {
    numerosPieces: { rows: [] },
    annee: 0,
    revenuNet: 0,
    unite: '',
    revenuActiviteAnnuelNet: 0,
    montantsDejaRevalorises: true,
  };

export const getDefaultPerteDeGainsProfessionnelsRow =
  (): PerteDeGainsProfessionnelsRow => ({
    numerosPieces: { rows: [] },
    dateDebut: null,
    dateFin: null,
    duree: null,
    revenuPercuNet: 0,
    revenuDeReferenceAnnuel: 0,
    revenuDeReferencePeriode: 0,
    revenuPercuParPeriode: 0,
    perteDeGainsProfessionnels: 0,
    unite: '',
    montantsDejaRevalorises: true,
    commentaires: '',
  });

export const getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow =
  (): IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow => ({
    numerosPieces: { rows: [] },
    tiersPayeur: '',
    dateDebut: null,
    dateFin: null,
    duree: null,
    indemniteJournalieresPercuesNet: 0,
    uniteIndemniteJournalieresPercuesNet: '',
    csgRds: null,
    uniteCsgRds: '',
    indemnitesPercuesNetParPeriode: null,
    csgRDSParPeriode: null,
    commentaires: '',
  });

export const newPGPFDefaultValues = ({
  procedure,
  values,
}: {
  procedure: Procedure;
  values: NewPrejudiceFormPerteGainProfessionnelsFuturs | undefined;
}): NewPrejudiceFormPerteGainProfessionnelsFuturs => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  revenuActiviteAnnuelDeReference: {
    rows:
      sortBy(values?.revenuActiviteAnnuelDeReference?.rows, 'annee').map(
        (row) => ({
          ...defaultRevenuActiviteAnnuelDeReferenceRow,
          ...row,
        }),
      ) || [],
    total: values?.revenuActiviteAnnuelDeReference?.total || 0,
  },
  perteDeGainsProfessionnels: {
    rows:
      sortBy(
        values?.perteDeGainsProfessionnels?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultPerteDeGainsProfessionnelsRow(),
        ...row,
      })) || [],
    total: values?.perteDeGainsProfessionnels?.total || 0,
    annualisation: values?.perteDeGainsProfessionnels?.annualisation || 0,
    revenusPercusTotal:
      values?.perteDeGainsProfessionnels?.revenusPercusTotal || 0,
    revenuReferenceTotal:
      values?.perteDeGainsProfessionnels?.revenuReferenceTotal || 0,
  },
  indemnitesJournalieresPercuesPendantLaPeriodeDArret: {
    rows:
      sortBy(
        values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow(),
        ...row,
      })) || [],
    csgRdsTotal:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.csgRdsTotal || null,
    indemnitesJournalieresPercuesNet:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.indemnitesJournalieresPercuesNet || null,
  },
  perteDeChanceDeGainProfessionnel: {
    rows:
      sortBy(
        values?.perteDeChanceDeGainProfessionnel?.rows,
        (row) => row.annee,
      ).map((row) => ({
        ...defaultPerteDeChanceDeGainProfessionnelRow,
        ...row,
      })) || [],
    totalIndemnite:
      values?.perteDeChanceDeGainProfessionnel?.totalIndemnite || 0,
    coefficientPerteDeChance:
      values?.perteDeChanceDeGainProfessionnel?.coefficientPerteDeChance || 100,
    perteDeChance: values?.perteDeChanceDeGainProfessionnel?.perteDeChance || 0,
  },
  typeBareme: values?.typeBareme,
  ageDernierArrerage: values?.ageDernierArrerage || null,
  isLastArrerageViager: values?.isLastArrerageViager || false,
  baremeCapitalisation: values
    ? values?.baremeCapitalisation
    : procedure.baremes?.baremeCapitalisationId ?? undefined,
  victimeSommeACapitaliser: values?.victimeSommeACapitaliser || 0,
  prixEuroRente: values?.prixEuroRente || 0,
  renteCapitalisee: values?.renteCapitalisee || 0,
  ageDateAttribution: values?.ageDateAttribution,
  capitalisationTiersPayeurs: {
    parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => ({
      tiersPayeur,
      sommeACapitaliser: 0,
      coefficient: 0,
      montantCapitalise: 0,
      ...values?.capitalisationTiersPayeurs?.parTiersPayeur?.find(
        (row) => row.tiersPayeur === tiersPayeur,
      ),
    })),
  },
  tiersPayeursTotalCapitalise: values?.tiersPayeursTotalCapitalise || 0,
  capitalConstitutifRente: values?.capitalConstitutifRente || 0,
  activatePGPFReliquat: values?.activatePGPFReliquat || false,
  PGPFReliquat: values?.PGPFReliquat || 0,
  commentaires: values?.commentaires || '',
  total: values?.total || 0,
  formType: values?.formType || 'REVENUS',
  isRentesOption: values?.isRentesOption || false,
  rentes: values?.rentes || {
    montant: 0,
  },
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'smic',
  enableCapitalisationDifferee: values?.enableCapitalisationDifferee ?? false,
});

interface Props
  extends PrejudiceFormProps<NewPrejudiceFormPerteGainProfessionnelsFuturs> {
  tauxIPP: number | null;
}

export const NewFormPerteGainsProfessionnelsFuturs: React.FC<Props> = ({
  victime,
  procedure,
  dommages,
  values,
  baremes,
  dateLiquidation,
  monetaryErosions,
  onPrevious,
  dateConsolidation,
  tauxIPP,
  allNumerosPieces,
  dateDeces,
  ...props
}) => {
  const [activeTab, setActiveTabs] = useState(0);
  const { t } = useTranslation();

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const useFormReturn = useForm<NewPrejudiceFormPerteGainProfessionnelsFuturs>({
    defaultValues: newPGPFDefaultValues({
      procedure,
      values,
    }),
    resolver: yupResolver(
      validationSchemaNewFormPerteGainsProfessionnelsFuturs({
        procedure,
        victime,
        prejudiceType: props.prejudiceType,
      }),
    ),
  });
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useFormReturn;

  const isFirstTabError = useMemo(() => {
    const firstTabFields: (keyof NewPrejudiceFormPerteGainProfessionnelsFuturs)[] =
      [
        'revenuActiviteAnnuelDeReference',
        'perteDeGainsProfessionnels',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
        'perteDeChanceDeGainProfessionnel',
      ];
    return (
      intersection(Object.keys(errors), firstTabFields).length > 0 &&
      activeTab !== 0
    );
  }, [errors, activeTab]);

  const isSecondTabError = useMemo(() => {
    const secondTabFields: (keyof NewPrejudiceFormPerteGainProfessionnelsFuturs)[] =
      [
        'typeBareme',
        'ageDernierArrerage',
        'isLastArrerageViager',
        'baremeCapitalisation',
        'victimeSommeACapitaliser',
        'prixEuroRente',
        'renteCapitalisee',
        'capitalisationTiersPayeurs',
        'capitalConstitutifRente',
        'activatePGPFReliquat',
        'PGPFReliquat',
      ];
    return (
      intersection(Object.keys(errors), secondTabFields).length > 0 &&
      activeTab !== 1
    );
  }, [errors, activeTab]);
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <>
      <PrejudiceContainer<NewPrejudiceFormPerteGainProfessionnelsFuturs>
        {...props}
        victime={victime}
        procedure={procedure}
        monetaryErosions={monetaryErosions}
        baremes={baremes}
        {...useFormReturn}
        renderPrejudice={({ setAdditionalTitle }) => (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <TextFieldForm
                control={control}
                name="notes"
                label={t('prejudice.fields.notes.label')}
                placeholder={t('prejudice.fields.notes.placeholder') || ''}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DintilhacButton onClick={() => setShowDintilhac(true)} />
                    </InputAdornment>
                  ),
                }}
                multiline
                maxRows={4}
                fullWidth
              />
              <DintilhacText
                open={showDintilhac}
                setOpen={setShowDintilhac}
                label={t('prejudice.fields.dintilhac.label')}
                content={i18next.t(
                  `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
                  {
                    defaultValue: '',
                  },
                )}
              />
              <Tabs
                value={activeTab}
                onChange={(_, value) => setActiveTabs(value)}
              >
                <PrejudiceTab
                  label={t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.PGPF',
                    {
                      context: victime.dateDeces ? 'deces' : undefined,
                    },
                  )}
                  value={0}
                  isError={isFirstTabError}
                />
                {!shouldNotDisplayCapitalisation ? (
                  <PrejudiceTab
                    label={t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.capitalisation',
                      {
                        context: victime.dateDeces ? 'deces' : undefined,
                      },
                    )}
                    value={1}
                    isError={isSecondTabError}
                  />
                ) : null}
                <PrejudiceTab
                  label={
                    t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.total',
                    ) || ''
                  }
                  value={2}
                />
              </Tabs>
            </Box>
            <Stack
              direction="column"
              spacing={4}
              sx={{ display: activeTab === 0 ? '' : 'none' }}
            >
              <NewStepManager
                setAdditionalTitle={setAdditionalTitle}
                procedure={procedure}
                dommage={dommages?.[0]}
                prejudiceType={props.prejudiceType}
                victime={victime}
                control={control}
                trigger={trigger}
                values={values}
                getValues={getValues}
                onClose={props.onClose}
                monetaryErosions={monetaryErosions}
                onPrevious={onPrevious ? onPrevious : undefined}
                handleSubmit={handleSubmit}
                isPGPF
                dateConsolidation={dateConsolidation}
                dateLiquidation={dateLiquidation}
                tauxIPP={tauxIPP}
                allNumerosPieces={allNumerosPieces}
                user={props.user}
              />
            </Stack>
            {!shouldNotDisplayCapitalisation ? (
              <Stack
                direction="column"
                sx={{ display: activeTab === 1 ? '' : 'none' }}
                spacing={2}
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TotalCapitalisation
                      control={control}
                      victimeName={{
                        sommeACapitaliser: 'victimeSommeACapitaliser',
                        bareme: 'baremeCapitalisation',
                        coefficient: 'prixEuroRente',
                        montantCapitalise: 'renteCapitalisee',
                        isLastArrerageViager: 'isLastArrerageViager',
                        ageDernierArrerage: 'ageDernierArrerage',
                        ageDateAttribution: 'ageDateAttribution',
                        enableCapitalisationDifferee:
                          'enableCapitalisationDifferee',
                      }}
                      procedure={procedure}
                      dateLiquidation={
                        dateLiquidation ? new Date(dateLiquidation) : null
                      }
                      baremes={capitalisationBaremes}
                      dateNaissance={
                        victime.dateNaissance
                          ? new Date(victime.dateNaissance)
                          : null
                      }
                      dateDeces={
                        victime.dateDeces ? new Date(victime.dateDeces) : null
                      }
                      sexe={victime.sexe}
                      tiersPayeursName={{
                        root: 'capitalisationTiersPayeurs.parTiersPayeur',
                        montantCapitalise: 'tiersPayeursTotalCapitalise',
                      }}
                      tiersPayeurSuffix={{
                        sommeACapitaliser: 'sommeACapitaliser',
                        montantCapitalise: 'montantCapitalise',
                        coefficient: 'coefficient',
                      }}
                      titles={{
                        total: {
                          title:
                            t(
                              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.title',
                            ) || '',
                        },
                        victime: {
                          sommeACapitaliser: t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
                          ),
                        },
                      }}
                      defaultValuesNames={{
                        victimeSommeACapitaliser:
                          'perteDeGainsProfessionnels.annualisation',
                      }}
                      totalName="capitalConstitutifRente"
                      editable={{
                        victime: {
                          montantCapitalise: true,
                          ageDateAttribution: true,
                        },
                      }}
                      editedFieldsName="editedFields"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <CalculsBox>
                      <Stack width="98%">
                        <TextFieldForm
                          control={control}
                          name="commentaires"
                          label="commentaires"
                          multiline
                          inputProps={{
                            sx: { minHeight: '200px' },
                          }}
                          maxRows={9}
                          fullWidth
                        />
                      </Stack>
                    </CalculsBox>
                  </Grid>
                </Grid>
                <SavePrejudiceButton
                  sx={{ alignSelf: 'center' }}
                  victime={victime}
                />
              </Stack>
            ) : null}
            <Stack sx={{ display: activeTab === 2 ? '' : 'none' }} spacing={4}>
              <TotalNewPerteGainsFuturs
                control={control as any}
                procedure={procedure}
                dateConsolidation={dateConsolidation}
                dateLiquidation={dateLiquidation}
                dateDeces={dateDeces}
              />
              <SavePrejudiceButton
                sx={{ alignSelf: 'center' }}
                victime={victime}
              />
            </Stack>
            <ComputedReadFieldForm
              control={control}
              name="perteDeGainsProfessionnels.annualisation"
              watchFields={['perteDeGainsProfessionnels.rows', 'editedFields']}
              compute={([rows, editedFields]) =>
                CalculsFormNewPerteGainsProfessionnelsFuturs.getPerteDeGainAnnualisee(
                  {
                    rows,
                    editedFields,
                  },
                ) || 0
              }
            />
          </>
        )}
      />
    </>
  );
};
