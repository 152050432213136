import {
  NumeroPieceValues,
  NumeroPieceValuesRow,
  PrejudiceType,
  chiffragesOptions,
  PrejudiceFormBase,
  CapitalisationValues,
  TiersPayeursCapitalisationValues,
  RentesValues,
  PrejudiceFormCapitalisationOrRenteBase,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import { prejudicesData } from '../prejudices';
import { MIN_TIMESTAMP, MAX_TIMESTAMP } from 'src/helpers/formatTime';
import i18next from 'i18next';
export const validationSchemaNumeroPieceRow: yup.ObjectSchema<
  NumeroPieceValuesRow,
  yup.AnyObject,
  {
    numeroPiece: undefined;
    libelle: undefined;
  },
  ''
> = yup.object({
  numeroPiece: yup.string().required('Ce champs est obligatoire'),
  libelle: yup.string().defined('Ce champs est obligatoire'),
});

export const validationSchemaNumeroPieces: yup.ObjectSchema<
  NumeroPieceValues,
  yup.AnyObject,
  {
    rows: '';
  },
  ''
> = yup.object({
  rows: yup
    .array()
    .defined()
    .of(validationSchemaNumeroPieceRow)
    .transform((_, rows) => {
      return rows.filter((row: NumeroPieceValuesRow) => !!row.numeroPiece);
    }),
});

export const getDefaultMinAndMaxDate = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): {
  minDate: {
    date: Date;
    message: string;
  };
  maxDate: {
    date: Date;
    message: string;
  };
} => {
  const { dateAccident, dateDeces, dateConsolidation, dateLiquidation } = {
    dateAccident: victime.dateAccident
      ? new Date(victime.dateAccident)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
  };
  const minDate = new Date(MIN_TIMESTAMP);
  const maxDate = new Date(MAX_TIMESTAMP);
  switch (prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES'].categorie) {
    case 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES':
    case 'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES':
      return {
        minDate: {
          date: dateAccident || minDate,
          message: dateAccident
            ? i18next.t('validation.prejudices.dates.minDateAccident')
            : i18next.t('validation.dates.dateTooLow'),
        },
        maxDate: {
          date: dateConsolidation || dateDeces || dateLiquidation || new Date(),
          message:
            dateConsolidation || dateDeces || dateLiquidation
              ? i18next.t(
                  `validation.prejudices.dates.${
                    dateConsolidation
                      ? 'maxDateConsolidation'
                      : dateDeces
                        ? 'maxDateDeces'
                        : dateLiquidation
                          ? 'maxDateLiquidation'
                          : ''
                  }`,
                )
              : i18next.t('validation.dates.dateTooHigh'),
        },
      };
    case 'PREJUDICES_PATRIMONIAUX_PERMANENTS':
    case 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS':
      return {
        minDate: {
          date: dateConsolidation || dateAccident || minDate,
          message:
            dateConsolidation || dateAccident
              ? i18next.t(
                  `validation.prejudices.dates.${
                    dateConsolidation
                      ? 'minDateConsolidation'
                      : dateAccident
                        ? 'minDateAccident'
                        : ''
                  }`,
                )
              : i18next.t('validation.dates.dateTooLow'),
        },
        maxDate: {
          date: dateDeces || maxDate,
          message: dateDeces
            ? i18next.t('validation.prejudices.dates.maxDateDeces')
            : i18next.t('validation.dates.dateTooHigh'),
        },
      };
    case 'AUTRES_PREJUDICES_HORS_CONSOLIDATION':
    case 'POSTES_ANNEXES':
    case 'VICTIMES_INDIRECTES_PATRIMONIAUX':
      return {
        minDate: {
          date: dateAccident || minDate,
          message: dateAccident
            ? i18next.t('validation.prejudices.dates.minDateAccident')
            : i18next.t('validation.dates.dateTooLow'),
        },
        maxDate: {
          date: maxDate,
          message: i18next.t('validation.dates.dateTooHigh'),
        },
      };
    default: {
      return {
        minDate: {
          date: minDate,
          message: i18next.t('validation.dates.dateTooLow'),
        },
        maxDate: {
          date: maxDate,
          message: i18next.t('validation.dates.dateTooHigh'),
        },
      };
    }
  }
};
export const validationObjectCapitalisation = {
  bareme: yup.string().optional(),
  isLastArrerageViager: yup.boolean().optional(),
  ageDernierArrerage: yup.lazy((value) =>
    value === ''
      ? (yup
          .string()
          .defined()
          .transform(() => null) as unknown as yup.NumberSchema<
          number,
          yup.AnyObject,
          undefined,
          ''
        >)
      : yup.number().defined().nullable().min(0),
  ),
  coefficient: yup.number().defined().nullable().min(0),
  montantCapitalise: yup.number().required(),
  ageDateAttribution: yup.number().optional(),
  enableCapitalisationDifferee: yup.boolean().optional(),
  dateAttributionType: yup
    .string()
    .oneOf(['premier_renouvellement', 'liquidation'])
    .optional(),
};
export const validationSchemaCapitalisation: yup.ObjectSchema<CapitalisationValues> =
  yup.object(validationObjectCapitalisation);

export const validationSchemaTiersPayeursCapitalisationParTiersPayeurObject = {
  tiersPayeur: yup.string().required(),
  sommeACapitaliser: yup.number().required(),
  coefficient: yup.number().required().min(0),
  montantCapitalise: yup.number().required(),
};

export const validationSchemaTiersPayeursCapitalisationObject = () => ({
  parTiersPayeur: yup
    .array()
    .defined()
    .of(
      yup.object(
        validationSchemaTiersPayeursCapitalisationParTiersPayeurObject,
      ),
    ),
  montantCapitalise: yup.number().required(),
});

export const validationSchemaTiersPayeursCapitalisation: yup.ObjectSchema<TiersPayeursCapitalisationValues> =
  yup.object(validationSchemaTiersPayeursCapitalisationObject());

export const validationChiffrage = yup
  .string()
  .oneOf(chiffragesOptions)
  .required();

export const validationSchemaPrejudiceBase: yup.ObjectSchema<PrejudiceFormBase> =
  yup.object({
    chiffrage: validationChiffrage,
    formVersion: yup.number().optional(),
    notes: yup.string().optional().defined(),
    editedFields: yup.array().defined(),
  });

export const validationSchemaRentes: yup.ObjectSchema<
  RentesValues,
  yup.AnyObject,
  undefined,
  'd'
> = yup
  .object({
    montant: yup.number().required(),
  })
  .default(undefined);

export const validationSchemaPrejudiceFormCapitalisationOrRenteBase: yup.ObjectSchema<PrejudiceFormCapitalisationOrRenteBase> =
  yup.object({
    isRentesOption: yup.boolean().required(),
    rentes: validationSchemaRentes.when('isRentesOption', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  });
