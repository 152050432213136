import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack } from '@mui/material';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { dateString } from 'src/helpers/yup';
import {
  PrejudiceFormCalendrierValeur,
  PrejudiceFormMateriel,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import * as yup from 'yup';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import {
  TableValeurs,
  getDefaultCalendrierValeursRow,
} from '../prejudiceFormComponents/TableValeurs';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';

export const validationSchemaMateriel =
  (): yup.ObjectSchema<PrejudiceFormMateriel> =>
    validationSchemaPrejudiceBase.shape({
      rows: yup
        .array()
        .defined()
        .of(
          yup.object({
            numerosPieces: validationSchemaNumeroPieces,
            date: dateString().nullable().defined(),
            montant: yup.number().required().min(0),
            intitule: yup.string().required(),
            montantsDejaRevalorises: yup.boolean().required(),
          }),
        ),
      partResponsabilite: yup
        .number()
        .required()
        .min(0)
        .max(100)
        .transform((value) => value / 100),
      total: yup.number().required().min(0),

      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['mensuel', 'annuel'])
        .required(),
    });
export const materielDefaultValues = ({
  procedure,
  values,
  multiplyPartResponsabilite,
}: {
  procedure: Procedure;
  values: PrejudiceFormMateriel | undefined;
  multiplyPartResponsabilite: boolean;
}): PrejudiceFormMateriel => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  rows: sortBy(values?.rows || [], (row) => row.date)?.map((row) => ({
    ...getDefaultCalendrierValeursRow(),
    ...row,
  })),
  total: values?.total || 0,
  partResponsabilite:
    (values?.partResponsabilite || values?.partResponsabilite === 0
      ? values?.partResponsabilite
      : procedure.partResponsabilite) * (multiplyPartResponsabilite ? 100 : 1),
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'annuel',
});
type Props = PrejudiceFormProps<PrejudiceFormMateriel>;

export const FormMateriel: React.FC<Props> = ({
  procedure,
  victime,
  values,
  monetaryErosions,
  displayTotalPartResponsabilite,
  allNumerosPieces,
  ...props
}) => {
  const useFormReturn = useForm<PrejudiceFormMateriel>({
    defaultValues: materielDefaultValues({
      procedure,
      values,
      multiplyPartResponsabilite: true,
    }),
    resolver: yupResolver(validationSchemaMateriel()),
  });
  const { t } = useTranslation();
  const { control } = useMemo(() => useFormReturn, [useFormReturn]);

  return (
    <PrejudiceContainer<PrejudiceFormMateriel>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            multiline
            maxRows={10}
            fullWidth
          />
          <ComputedTextFieldForm
            control={control}
            name="partResponsabilite"
            label={t('partResponsabilite.label')}
            sx={{ alignSelf: 'flex-end' }}
            watchFields={[]}
            compute={() => procedure.partResponsabilite * 100}
            InputLabelProps={{
              sx: { color: 'info.light' },
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2, min: 0, max: 100 },
                suffix: '%',
              } as IMaskNumberProps,
              sx: {
                color: 'info.light',
                borderColor: 'info.light',
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'info.light',
                },
              },
            }}
          />
          <TableValeurs
            control={
              control as Control<
                PrejudiceFormCalendrierValeur | PrejudiceFormMateriel
              >
            }
            dateLiquidation={procedure.dateLiquidation}
            monetaryErosions={monetaryErosions}
            procedure={procedure}
            victime={victime}
            prejudiceType={props.prejudiceType}
            allNumerosPieces={allNumerosPieces}
          />
          {displayTotalPartResponsabilite && (
            <ComputedPropsForm
              control={control}
              watchFields={['total', 'partResponsabilite']}
              compute={([total, partResponsabilite]) => ({
                props: {
                  totalPartResponsabilite:
                    (Number(total) * partResponsabilite) / 100,
                  total: Number(total),
                  partResponsabilite: Number(partResponsabilite / 100),
                },
              })}
              render={({
                totalPartResponsabilite,
                total,
                partResponsabilite,
              }) => (
                <Stack alignSelf="end" maxWidth="400px">
                  <TotalDeficit
                    totalPartResponsabilite={totalPartResponsabilite}
                    totalMontantTotal={total}
                    displayPartResponsabilite={displayTotalPartResponsabilite}
                    partResponsabilite={partResponsabilite}
                  />
                </Stack>
              )}
            />
          )}
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
