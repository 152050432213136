import { FieldValues } from 'react-hook-form';
import {
  MonetaryErosionsCoefficientsType,
  monetaryErosionsCoefficientsTypes,
} from './monetaryErosion.type';
import { BaremeRIIRoundChoice } from './procedure.type';
import { RegimeType } from './regime.type';

export interface Prejudice {
  _id: string;
  type: PrejudiceType;
  formType: PrejudiceFormType;
  formData: PrejudiceFormValues;
  parent?: string;
}

const prejudicesCategoriesTypes = ['DIRECTE', 'INDIRECTE'] as const;
export type PrejudiceCategorieType = (typeof prejudicesCategoriesTypes)[number];

export const PREJUDICE_CATEGORIE_LIST = [
  'PREJUDICES_PATRIMONIAUX_PERMANENTS',
  'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
  'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
  'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
  'AUTRES_PREJUDICES_HORS_CONSOLIDATION',
  'POSTES_ANNEXES',
  'VICTIMES_INDIRECTES_PATRIMONIAUX',
  'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
] as const;
export type PrejudiceCategorie = (typeof PREJUDICE_CATEGORIE_LIST)[number];

export const PREJUDICE_TYPE_LIST = [
  'DEPENSE_SANTE_FUTURES',
  'FRAIS_DIVERS_FUTURS',
  'FRAIS_LOGEMENT_ADAPTE',
  'FRAIS_VEHICULE_ADAPTE',
  'PERTES_GAINS_PROFESSIONNELS_FUTURS',
  'INCIDENCE_PROFESSIONNELLE',
  'ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE',
  'DEFICIT_FONCTIONNEL_PERMANENT',
  'PREJUDICE_AGREMENT',
  'PREJUDICE_ESTHETIQUE_PERMANENT',
  'PREJUDICE_SEXUEL',
  'PREJUDICE_ETABLISSEMENT',
  'PREJUDICE_PERMANENT_EXCEPTIONNEL',
  'DEPENSE_SANTE_ACTUELLES',
  'FRAIS_DIVERS_ACTUELS',
  'PERTES_GAINS_PROFESSIONNELS_ACTUELS',
  'PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION',
  'ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE',
  'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
  'DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
  'SOUFFRANCES_ENDUREES',
  'PREJUDICE_ESTHETIQUE_TEMPORAIRE',
  'PREJUDICE_LIES_PATHOLOGIES_EVOLUTIVES',
  'PREJUDICE_IMPREPARATION_MEDICALE',
  'PREJUDICE_ANXIETE',
  'PREJUDICE_MATERIEL',
  'CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS',
  'AUTRES_POSTES_DE_PREJUDICES',
  'PREJUDICE_DANGOISSE_DE_MORT_IMMINENTE',
  'INDEMNITE_PROVISIONNELLE',
  'PERTES_REVENUS_DES_PROCHES',
  'FRAIS_OBSEQUES',
  'PREJUDICE_ACCOMPAGNEMENT_FIN_DE_VIE',
  'FRAIS_DIVERS_DES_PROCHES',
  'PREJUDICE_AFFECTION_OU_MORAL',
  'PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS',
  'PREJUDICE_ANGOISSE_ET_INQUIETUDE',
] as const;
export type PrejudiceType = (typeof PREJUDICE_TYPE_LIST)[number];

export const PREJUDICE_FORM_TYPE_LIST = [
  'VALEUR',
  'CALENDRIER_VALEURS',
  'CALENDRIER_HOSPITALISATION',
  'CALENDRIER_DEPENSE',
  'CALENDRIER_DEPENSE_CAPITALISATION',
  'CALENDRIER_ASSISTANCE',
  'CALENDRIER_ASSISTANCE_CAPITALISATION',
  'PERTE_GAINS_PROFESSIONNELS_ACTUEL',
  'PERTE_GAINS_PROFESSIONNELS_FUTURS',
  'RETRAITE',
  'LISTE_PROJECTION',
  'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
  'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
  'PERTES_REVENUS_PROCHE',
  'VALEUR_VICTIMES_INDIRECTES',
  'MATERIEL',
  'SCOLAIRE',
] as const;
export type PrejudiceFormType = (typeof PREJUDICE_FORM_TYPE_LIST)[number];

/* DTO */

export type CreatePrejudiceDto = Omit<Prejudice, '_id'>;

export type UpdatePrejudiceDto = Partial<
  Omit<CreatePrejudiceDto, 'type' | 'formType'>
>;
export type UpdateMultiplePrejudicesDto = {
  prejudices: UpdatePrejudiceDto & { _id: string }[];
};

/* Forms  */

export interface NumeroPieceValuesRow {
  numeroPiece: string;
  libelle: string;
}
export interface NumeroPieceValues {
  rows: NumeroPieceValuesRow[];
}

export const capitalisationDateAttributionTypes = [
  'premier_renouvellement',
  'liquidation',
] as const;

export type CapitalisationDateAttributionType =
  (typeof capitalisationDateAttributionTypes)[number];

export interface CapitalisationValues {
  isLastArrerageViager?: boolean;
  bareme?: string;
  ageDernierArrerage?: number | null;
  coefficient: number | null;
  montantCapitalise: number;
  ageDateAttribution?: number | null;
  enableCapitalisationDifferee?: boolean;
  dateAttributionType?: CapitalisationDateAttributionType;
}
export interface TiersPayeursCapitalisationValues {
  parTiersPayeur: ({
    tiersPayeur: string;
    sommeACapitaliser: number;
  } & Omit<
    CapitalisationValues,
    | 'isLastArrerageViager'
    | 'bareme'
    | 'ageDernierArrerage'
    | 'enableCapitalisationDifferee'
  >)[];
  montantCapitalise: number;
}

export interface RentesValues {
  montant: number;
}

export interface PrejudiceFormCapitalisationOrRenteBase {
  rentes?: RentesValues;
  isRentesOption: boolean;
}

export const chiffragesOptions = ['en_cours', 'fait'] as const;
export type ChiffrageOption = (typeof chiffragesOptions)[number];

export interface PrejudiceFormBase {
  chiffrage: ChiffrageOption;
  formVersion?: number;
  notes: string;
  // TODO Find a better stricter type. We need to add | string because the components that accept PrejudiceFormBase as props don't accept the same editedFields as a Form that extends PrejudiceFormBase
  editedFields: (keyof Omit<this, 'editedFields'> | string)[];
}

export interface PrejudiceFormValeur extends PrejudiceFormBase {
  numerosPieces: NumeroPieceValues;
  argument?: string;
  montant: number;
  partResponsabilite?: number;
}

/* Common types */

type TiersPayeursValue = {
  tiersPayeur: string;
  montant: number;
};

/* Calendrier depense */

export const depenseFrequences = [
  'non_renseigne',
  'quotidien',
  'hebdomadaire',
  'mensuel',
  'annuel',
  'viagere',
] as const;

export type DepenseFrequence = (typeof depenseFrequences)[number];

export const depenseTypes = ['ponctuelle', 'recurrente'] as const;
export type DepenseType = (typeof depenseTypes)[number];
export interface PrejudiceFormCalendrierDepenseRow {
  type: DepenseType;
  numerosPieces: NumeroPieceValues;
  intitule: string;
  dateDebut?: string | null;
  dateFin?: string | null;
  dureeDansUniteChoisie: number;
  frequenceMontant: DepenseFrequence;
  dateJustificatif: string | null;
  montantsDejaRevalorises: boolean;
  quantite: number;
  montantUnitaire: number;
  montantTotal: number;
  priseEnChargeTiersPayeurs: TiersPayeursValue[];
  priseEnChargeTiersPayeursProratises: TiersPayeursValue[];
  resteACharge: number;
  renouvellementMaintenance?: number | null;
  victimeIndirecte?: string;
}

export const depenseRevalorisationCoefficientsTypes =
  monetaryErosionsCoefficientsTypes.filter(
    (type): type is Exclude<MonetaryErosionsCoefficientsType, 'smic'> =>
      type !== 'smic',
  );
export type DepenseRevalorisationCoefficientsType =
  (typeof depenseRevalorisationCoefficientsTypes)[number];
export interface PrejudiceFormCalendrierDepense extends PrejudiceFormBase {
  rows: PrejudiceFormCalendrierDepenseRow[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'smic'
  >;
  partResponsabilite?: number;
}

/* Calendrier depense capitalisation */

export interface PrejudiceFormCalendrierDepenseCapitalisationApresDecisionRowLegacy {
  type: DepenseType;
  numerosPieces: NumeroPieceValues;
  intitule: string;
  dateDebut?: string | null;
  dateFin?: string | null;
  dureeDansUniteChoisie: number;
  frequenceMontant: DepenseFrequence;
  dateJustificatif: string | null;
  montantsDejaRevalorises: boolean;
  quantite: number;
  montantUnitaire: number;
  montantTotal: number;
  priseEnChargeTiersPayeurs: TiersPayeursValue[];
  priseEnChargeTiersPayeursProratises: TiersPayeursValue[];
  resteACharge: number;
  depenseFutureAPrevoir?: boolean;
  renouvellementMaintenance?: number | null;
  victimeIndirecte?: string;
  montantUnitaireAnnualise?: number;
  totalTiersPayeurs?: number;
  capitalisation: Omit<CapitalisationValues, 'bareme'> & {
    dateAttribution?: string | null;
  };
  capitalisationTiersPayeurs: TiersPayeursCapitalisationValues;
  rentes?: RentesValues;
}

export interface PrejudiceFormCalendrierDepenseCapitalisationApresDecisionLegacy
  extends Pick<CapitalisationValues, 'bareme'> {
  rows: PrejudiceFormCalendrierDepenseCapitalisationApresDecisionRowLegacy[];
}

export type PrejudiceFormCalendrierDepenseCapitalisationRowLegacy =
  PrejudiceFormCalendrierDepenseRow;
export interface PrejudiceFormCalendrierDepenseCapitalisationLegacy
  extends PrejudiceFormBase,
    PrejudiceFormCapitalisationOrRenteBase,
    Pick<
      CapitalisationValues,
      'ageDateAttribution' | 'ageDernierArrerage' | 'isLastArrerageViager'
    > {
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'smic'
  >;
  rows: PrejudiceFormCalendrierDepenseCapitalisationRowLegacy[];
  victimeSommeACapitaliser: number;
  baremeCapitalisation?: string;
  victimeCoefficientCapitalisation: number | null;
  victimeTotalCapitalise: number;
  capitalisationTiersPayeurs: Omit<
    TiersPayeursCapitalisationValues,
    'montantCapitalise'
  >;
  tiersPayeursTotalCapitalise: number;
  commentaires: string | null;
  montantUnitaireAnnualise: number;
  apresDecision: PrejudiceFormCalendrierDepenseCapitalisationApresDecisionLegacy;
}

/* New Calendrier depense capitalisation */

export interface PrejudiceFormCalendrierDepenseCapitalisationRow
  extends PrejudiceFormCalendrierDepenseRow {
  montantUnitaireAnnualise?: number;
  capitalisation: Omit<
    CapitalisationValues,
    'bareme' | 'enableCapitalisationDifferee'
  > & {
    dateAttribution?: string | null;
  };
  capitalisationTiersPayeurs: Omit<
    TiersPayeursCapitalisationValues,
    'montantCapitalise'
  >;
}

export interface PrejudiceFormCalendrierDepenseCapitalisation
  extends PrejudiceFormBase,
    PrejudiceFormCapitalisationOrRenteBase {
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'smic'
  >;
  rows: PrejudiceFormCalendrierDepenseCapitalisationRow[];
  capitalisation: Pick<
    CapitalisationValues,
    | 'bareme'
    | 'montantCapitalise'
    | 'enableCapitalisationDifferee'
    | 'dateAttributionType'
  >;
  capitalisationTiersPayeurs: Pick<
    TiersPayeursCapitalisationValues,
    'montantCapitalise'
  > & {
    parTiersPayeur: Pick<
      TiersPayeursCapitalisationValues['parTiersPayeur'][number],
      'montantCapitalise' | 'tiersPayeur'
    >[];
  };
}

/* Calendrier assistance */

export const unites = ['jour', 'semaine', 'mois', 'annee'] as const;

export type Unite = (typeof unites)[number];

export const assistanceUniteNombresHeure = unites.filter(
  (unite): unite is Exclude<Unite, 'annee'> => unite !== 'annee',
);

export type AssistanceUniteNombreHeure =
  (typeof assistanceUniteNombresHeure)[number];

export const typesAssistance = [
  'aideFamilleOuProchesActive',
  'aideFamilleOuProchesPassive',
  'aideProfiessionnelleNonMedicaleActive',
  'aideProfiessionnelleNonMedicalePassive',
  'aideProfessionnelleMedicaleActive',
  'aideALaParentalite',
  'gestionDuPersonnelParLaVictime',
  'aideHumaine',
];

export type TypeAssistance = (typeof typesAssistance)[number];

export const defaultJoursConges = [365, 390, 400, 410, 412];
export type DefaultJoursConges = (typeof defaultJoursConges)[number];

export const assistanceRowTypes = ['echu', 'aEchoir'] as const;
export type AssistanceRowType = (typeof assistanceRowTypes)[number];

export interface PrejudiceFormCalendrierAssistanceRow {
  numerosPieces: NumeroPieceValues;
  dateDebut: string | null;
  dateFin: string | null;
  joursPeriode: number;
  joursHospitalisationADeduire: number;
  deduireJoursHospitalisation: boolean;
  joursRetenus: number;
  joursConges: number;
  totalJours: number;
  typeAssistance?: TypeAssistance | string;
  forfaitHoraire: number;
  nombreHeureParUniteChoisie: number;
  uniteNombreHeure: AssistanceUniteNombreHeure;
  dateJustificatif: string | null;
  montantsDejaRevalorises: boolean;
  montantTotal: number;
  annualiseMontantTotal?: number;
  quantite: number;
  priseEnChargeTiersPayeurs: TiersPayeursValue[];
  deductionFiscale: number;
  autresDeductions: number;
  resteACharge: number;
  commentaires?: string | null;
  notes: string;
  type: AssistanceRowType;
}

export interface PrejudiceFormCalendrierAssistance extends PrejudiceFormBase {
  rows: PrejudiceFormCalendrierAssistanceRow[];
  forfaitHoraire: number | null;
  isDFTPRowsAdded?: boolean;
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}

export interface PrejudiceFormCalendrierAssistanceCapitalisation
  extends Omit<PrejudiceFormCalendrierAssistance, 'rows' | 'isDFTPRowsAdded'>,
    PrejudiceFormCapitalisationOrRenteBase,
    Pick<
      CapitalisationValues,
      'ageDateAttribution' | 'ageDernierArrerage' | 'isLastArrerageViager'
    > {
  rows: PrejudiceFormCalendrierAssistanceRow[];
  forfaitHoraire: number | null;
  baremeCapitalisation?: string;
  victimeCoefficientCapitalisation: number | null;
  victimeSommeACapitaliser: number;
  victimeTotalCapitalise: number;
  capitalisationTiersPayeurs: Omit<
    TiersPayeursCapitalisationValues,
    'montantCapitalise'
  >;
  tiersPayeursTotalCapitalise: number;
  commentaires: string | null;
  totalMontantTotalAnnualise: number;
}

/* Calendrier Deficit fonctionnel Temporaire Total */

export interface PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow {
  numerosPieces: NumeroPieceValues;
  dateDebut: string | null;
  dateFin: string | null;
  totalJours: number;
  forfaitJour: number;
  montantTotal: number;
  motifHospitalisation: string;
}

export interface PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal
  extends PrejudiceFormBase {
  rows: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow[];
  forfaitJour: number | null;
}

/* Calendrier Deficit fonctionnel Temporaire Partiel */

export const commentairesOptions = [
  "Préjudice d'agrément temporaire",
  'Préjudice sexuel temporaire',
  "Préjudice d'établissement temporaire",
  'Préjudice exceptionnel temporaire',
  "Perte d'espoir et de chance de réaliser un projet de vie familiale en raison de la gravité du handicap et le préjudice sexuel subis",
] as const;
export type CommentairesOption = (typeof commentairesOptions)[number];

export const DFTPRowTypes = ['dftp', 'autreComposante'] as const;
export type DFTPRowType = (typeof DFTPRowTypes)[number];
export interface PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow {
  numerosPieces: NumeroPieceValues;
  dateDebut: string | null;
  dateFin: string | null;
  joursPeriode: number;
  joursHospitalisationADeduire?: number;
  deduireJoursHospitalisation?: boolean;
  totalJours: number;
  percentage?: number | null;
  forfaitJour?: number;
  montantTotal: number;
  commentaires?: string | null | CommentairesOption;
  type: DFTPRowType;
}

export interface PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel
  extends PrejudiceFormBase {
  forfaitJour: number | null;
  rows: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow[];
  total: number;
}

/* Liste projection */
export interface ListeProjectionAgrementRow {
  numerosPieces: NumeroPieceValues;
  typePrejudice: string;
  montant: number;
}
export interface ListeProjectionAgrement {
  rows: ListeProjectionAgrementRow[];
}

export const COMMENTAIRE_PGPF_RELIQUAT = 'PGPF_RELIQUAT' as const;
export type COMMENTAIRE_PGPF_RELIQUAT_TYPE = typeof COMMENTAIRE_PGPF_RELIQUAT;

export interface ListeProjectionFonctionnelPermanentRow {
  numerosPieces: NumeroPieceValues;
  commentaires: string;
  pourcentage: number;
  valeurPoint?: number;
  montant: number;
}
export interface ListeProjectionFonctionnelPermanent {
  rows: ListeProjectionFonctionnelPermanentRow[];
}

export const LIBELLE_PGPF_RELIQUAT = 'PGPF_RELIQUAT' as const;
export type LIBELLE_PGPF_RELIQUAT_TYPE = typeof LIBELLE_PGPF_RELIQUAT;

export interface PerteDeRetraiteValues {
  retraite: {
    anneeRetraite: number | null;
    retraiteTheoriqueTotal: number;
    retraitePercue: number;
    perteAnnuelle: number;
  };
  echus: {
    nombreAnneesEntreAnneeRetraiteEtAnneeCalcul: number;
    montantAPayerADateCalcul: number;
  };
  aEchoir: CapitalisationValues & {
    sommeACapitaliser: number;
    dateCapitalisation: string | null;
  };
}

export interface ListeProjectionIncidenceProfessionelleRow {
  numerosPieces: NumeroPieceValues;
  libelle: string;
  montant: number;
}
export interface ListeProjectionIncidenceProfessionelle {
  rows: ListeProjectionIncidenceProfessionelleRow[];
  perteDeRetraite: PerteDeRetraiteValues;
}

export interface ListeProjectionMaladieRow {
  numerosPieces: NumeroPieceValues;
  maladie: string;
  dateDebut: string | null;
  dateFin: string | null;
  jours: number;
  degre?: number;
  montant: number;
}
export interface ListeProjectionMaladie {
  rows: ListeProjectionMaladieRow[];
}

export interface PrejudiceFormListeProjection extends PrejudiceFormBase {
  prejudiceValues?:
    | ListeProjectionAgrement
    | ListeProjectionFonctionnelPermanent
    | ListeProjectionMaladie
    | ListeProjectionIncidenceProfessionelle;
  montantTotal: number;
  enableProrataTemporisDeces: boolean;
  anneeCapitalisation?: number;
  baremeCapitalisation?: string;
  baremeIndemnisation?: string;
  baremeValueChoice?: string;
  baremeRII?: string;
  baremeRIIRoundAge?: BaremeRIIRoundChoice | null;
  baremeRIIRoundTauxDFP?: BaremeRIIRoundChoice | null;
  coefficientCapitalisationAgeConsolidation?: number | null;
  montantProratise?: number;
}

export interface RenteAccidentDuTravail {
  tauxIPP: number | null;
  salaireAnnuelDeReference: number | null;
}

/* Perte gains professionnel actuel */

export const PrejudiceFormPerteGainProfessionelsFormTypes = [
  'DECLARATION_FISCALES',
  'REVENUS',
  'SAISIE_DIRECTE',
] as const;
export type PrejudiceFormPerteGainProfessionelsFormType =
  (typeof PrejudiceFormPerteGainProfessionelsFormTypes)[number];

export interface PrejudiceFormPerteGainProfessionnelsBase {
  formType?: PrejudiceFormPerteGainProfessionelsFormType;
}

/* Méthode Déclaration fiscales */

export interface PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee {
  numerosPieces: NumeroPieceValues;
  annee: number;
  netFiscal?: number | null;
  netPaye?: number | null;
  smicBrut?: number;
  victimeRedevable: boolean;
  montantsDejaRevalorises: boolean;
}

export interface PerteGainProfessionnelsActuelSituationRevenusReelsAnnee {
  numerosPieces: NumeroPieceValues;
  annee: number;
  netFiscal: number;
  netPaye?: number;
  smicBrut: number;
  victimeRedevable: boolean;
  montantsDejaRevalorises: boolean;
}

export interface PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee {
  annee: number;
  netPaye?: number | null;
  netFiscal?: number | null;
  csgRds?: number | null;
}

export interface PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs {
  numerosPieces: NumeroPieceValues;
  tiersPayeur: string;
  netPaye?: number;
  csgRds: number | null;
  anneeTiersPayeursIndemnites: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee[];
}

export interface PerteGainProfessionnelsActuelSituation {
  regime: RegimeType;
  anneesRevenusTheoriques: PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee[];
  revenuDeReference: number | null;
  revenuTheorique: number;
  totalPerteDeGain: number;
  anneesRevenusReels: PerteGainProfessionnelsActuelSituationRevenusReelsAnnee[];
  arrerageVictime: number;
  revenusReelsNet: number;
  tiersPayeursIndemnites: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[];
  totalIndemnitesNet: number;
  totalIndemnitesCsgCrds: number;
}

export interface OldPrejudiceFormPerteGainProfessionnelsActuel
  extends PrejudiceFormBase,
    PrejudiceFormPerteGainProfessionnelsBase {
  dateDebut: string | null;
  dateFin: string | null;
  situations: PerteGainProfessionnelsActuelSituation[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}

/* Méthode Revenus */

export interface PerteDeChanceDeGainProfessionnelRow {
  numerosPieces: NumeroPieceValues;
  annee: number | null;
  indemnite: number;
  unite: Unite | '';
  commentaires: string;
}

export interface PerteDeChanceDeGainProfessionnel {
  rows: PerteDeChanceDeGainProfessionnelRow[];
  coefficientPerteDeChance: number;
  totalIndemnite: number;
  perteDeChance: number;
}

export type RevenuActiviteAnnuelDeReferenceRow = {
  numerosPieces: NumeroPieceValues;
  revenuActiviteAnnuelNet: number;
  annee: number | null;
  revenuNet: number;
  unite: Unite | '';
  montantsDejaRevalorises: boolean;
};

export interface RevenuActiviteAnnuelDeReference {
  rows: RevenuActiviteAnnuelDeReferenceRow[];
  total: number;
}

export interface PerteDeGainsProfessionnelsRow {
  numerosPieces: NumeroPieceValues;
  dateDebut: string | null;
  dateFin: string | null;
  duree: number | null;
  revenuPercuNet: number;
  revenuDeReferenceAnnuel: number;
  revenuDeReferencePeriode: number;
  revenuPercuParPeriode: number;
  perteDeGainsProfessionnels: number;
  unite: Unite | '';
  montantsDejaRevalorises: boolean;
  commentaires: string;
}

export interface PertesDeGainsProfessionnels {
  rows: PerteDeGainsProfessionnelsRow[];
  total: number;
  revenusPercusTotal: number;
  revenuReferenceTotal: number;
}
export interface PertesDeGainsProfessionnelsPGPF
  extends PertesDeGainsProfessionnels {
  annualisation: number;
}

export interface IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow {
  numerosPieces: NumeroPieceValues;
  tiersPayeur: string;
  dateDebut: string | null;
  dateFin: string | null;
  duree: number | null;
  indemniteJournalieresPercuesNet: number;
  uniteIndemniteJournalieresPercuesNet: Unite | '';
  csgRds: number | null;
  uniteCsgRds: Unite | '';
  indemnitesPercuesNetParPeriode: number | null;
  csgRDSParPeriode: number | null;
  commentaires: string;
}

export interface IndemnitesJournalieresPercuesPendantLaPeriodeDArret {
  rows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[];
  csgRdsTotal: number | null;
  indemnitesJournalieresPercuesNet: number | null;
}

export interface NewPrejudiceFormPerteGainProfessionnelsActuel
  extends PrejudiceFormBase,
    PrejudiceFormPerteGainProfessionnelsBase {
  revenuActiviteAnnuelDeReference: RevenuActiviteAnnuelDeReference;
  perteDeGainsProfessionnels: PertesDeGainsProfessionnels;
  indemnitesJournalieresPercuesPendantLaPeriodeDArret: IndemnitesJournalieresPercuesPendantLaPeriodeDArret;
  perteDeChanceDeGainProfessionnel: PerteDeChanceDeGainProfessionnel;
  total: number;
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}

/* Méthode Saisie directe */

export interface RevenuAnnuelEspereRow {
  numerosPieces: NumeroPieceValues;
  revenuEspere: number;
  unite: Unite | '';
  coefficientPerteDeChance: number;
  perteDeChance: number;
}

export interface RevenuAnnuelEspere {
  rows: RevenuAnnuelEspereRow[];
  total: number;
}

export interface PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
  extends PrejudiceFormBase,
    PrejudiceFormPerteGainProfessionnelsBase {
  revenuEspere: RevenuAnnuelEspere;
  perteDeGainsProfessionnels: PertesDeGainsProfessionnels;
  indemnitesJournalieresPercuesPendantLaPeriodeDArret: IndemnitesJournalieresPercuesPendantLaPeriodeDArret;
  total: number;
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}

/* Perte gains professionels futur */

export interface PGPFReliquatAndPGPFReliquatActivationStatus {
  PGPFReliquat: number | null;
  activatePGPFReliquat: boolean | null;
}
export interface PerteGainProfessionnelsFutursBase
  extends PGPFReliquatAndPGPFReliquatActivationStatus,
    PrejudiceFormCapitalisationOrRenteBase,
    Pick<
      CapitalisationValues,
      'ageDateAttribution' | 'enableCapitalisationDifferee'
    > {
  capitalisationTiersPayeurs: Omit<
    TiersPayeursCapitalisationValues,
    'montantCapitalise'
  >;
  tiersPayeursTotalCapitalise: number;
}

/* Méthode Revenus */

export interface PerteGainProfessionnelsFutursSituation
  extends PerteGainProfessionnelsActuelSituation {
  tiersPayeursIndemnites: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[];
}

export interface OldPrejudiceFormPerteGainProfessionnelsFuturs
  extends OldPrejudiceFormPerteGainProfessionnelsActuel,
    PerteGainProfessionnelsFutursBase {
  situations: PerteGainProfessionnelsFutursSituation[];
  victimeSommeACapitaliser: number;
  isLastArrerageViager?: boolean;
  baremeCapitalisation?: string;
  ageDernierArrerage: number | null;
  victimeCoefficientCapitalisation: number | null;
  victimeTotalCapitalise: number;
  totalRevenuDeReference: number | null;
  totalPerteDeGainAnnualise: number | null;
  totalCapitalisation: number;
}

/* Méthode Déclaration fiscales */

export interface NewPrejudiceFormPerteGainProfessionnelsFuturs
  extends NewPrejudiceFormPerteGainProfessionnelsActuel,
    PerteGainProfessionnelsFutursBase {
  perteDeGainsProfessionnels: PertesDeGainsProfessionnelsPGPF;
  commentaires: string | null;
  typeBareme?: string | null;
  victimeSommeACapitaliser: number;
  isLastArrerageViager?: boolean;
  baremeCapitalisation?: string;
  ageDernierArrerage: number | null;
  prixEuroRente: number | null;
  renteCapitalisee: number;
  capitalConstitutifRente: number;
}

/* Méthode Saisie directe */

export interface PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
  extends PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
    Omit<
      PerteGainProfessionnelsFutursBase,
      | 'capitalisationTiersPayeurs'
      | 'tiersPayeursTotalCapitalise'
      | 'ageDateAttribution'
      | 'enableCapitalisationDifferee'
    > {
  capitalisation: CapitalisationValues & { sommeACapitaliser: number };
  capitalisationTiersPayeurs: TiersPayeursCapitalisationValues;
  totalCapitalisation: number;
  perteDeGainsProfessionnels: PertesDeGainsProfessionnelsPGPF;
}
export interface PrejudiceFormCalendrierValeurRow {
  numerosPieces: NumeroPieceValues;
  intitule: string;
  date: string | null;
  montantsDejaRevalorises?: boolean;
  montant: number;
}

export interface PrejudiceFormCalendrierValeur extends PrejudiceFormBase {
  rows: PrejudiceFormCalendrierValeurRow[];
  total: number;
}

/* Prejudice Materiel */

export interface PrejudiceFormMateriel extends PrejudiceFormBase {
  partResponsabilite: number;
  rows: PrejudiceFormCalendrierValeurRow[];
  total: number;
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'smic'
  >;
}

export interface PrejudiceFormScolairePerteDeChanceRow {
  numerosPieces: NumeroPieceValues;
  libelle: string;
  date: string | null;
  montant: number;
  coefficientPerteDeChance: number;
}

export interface PrejudiceFormScolairePerteDeChance {
  rows: PrejudiceFormScolairePerteDeChanceRow[];
  total: number;
}

export interface PrejudiceFormScolaire extends PrejudiceFormBase {
  montant: number;
  numerosPieces: NumeroPieceValues;
  perteDeChance: PrejudiceFormScolairePerteDeChance;
  total: number;
}
/* Victimes indirectes */

/* Perte revenus proche */

export interface CapitauxDeces {
  tiersPayeurs: TiersPayeursValue[];
  total: number;
}

export interface RevenuProche {
  montant: number;
  annee: number | null;
  dejaRevalorise: boolean;
  revaloriseMontant: number;
  numerosPieces: NumeroPieceValues;
}

export interface PrejudiceFormPerteRevenusProcheEnfants {
  rows: PrejudiceFormPerteRevenusProcheEnfantRow[];
  partTotale: number;
  total: number;
  totalCapitauxDeces: number;
  totalApresCapitauxDeces: number;
}

export interface PrejudiceFormPerteRevenusProcheSharedValues {
  partResponsabilite: number;
  baremeCapitalisation: string;
  enfants: PrejudiceFormPerteRevenusProcheEnfants;
}
export interface PrejudiceFormPerteRevenusProcheUnePeriodeValues
  extends PrejudiceFormPerteRevenusProcheSharedValues {
  victime: {
    revenu: RevenuProche;
    coefficientCapitalisation?: number | null;
  };
  conjoint: {
    revenuAvantDeces: RevenuProche;
    revenuApresDeces: RevenuProche;
    partTotale: number;
    coefficientCapitalisation?: number | null;
    total: number;
    capitauxDeces: CapitauxDeces;
    totalApresCapitauxDeces: number;
  };
  foyer: {
    revenuAnnuel: number;
    pourcentageConsomVictime: number | null;
    deductionPartConsomVictime: number;
    perteAnnuelle: number;
    prejudiceViagerEntreDecesEtLiquidation: number;
    prejudiceViagerApresLiquidation: number;
    prejudiceViager: number;
  };
  baremeCapitalisation: string;
  enfants: {
    rows: PrejudiceFormPerteRevenusProcheEnfantRow[];
    partTotale: number;
    total: number;
    totalCapitauxDeces: number;
    totalApresCapitauxDeces: number;
  };
  partResponsabilite: number;
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}

const perteRevenusProcheCapitalisationTypes = [
  'ageTheoriqueRetraite',
  'renteDifferee',
] as const;
export type PerteRevenusProcheCapitalisationType =
  (typeof perteRevenusProcheCapitalisationTypes)[number];

type PrejudiceFormPerteRevenusProcheCapitalisationAgeTheoriqueRetraite = Pick<
  CapitalisationValues,
  'isLastArrerageViager' | 'coefficient'
>;
interface PrejudiceFormPerteRevenusProcheCapitalisationRenteDifferee {
  coefficientAgeLiquidation: number;
  coefficientEntreAgeLucidationEtAgeRetraite: number;
  coefficient: number;
}
export interface PrejudiceFormPerteRevenusProcheDeuxPeriodesValues
  extends PrejudiceFormPerteRevenusProcheSharedValues {
  victime: {
    ageRetraite: number;
    dateRetraite: string | null;
    revenuAvantRetraite: number;
    revenuEspereApresRetraite: number;
  };
  conjoint: {
    revenuAvantDeces: number;
    revenuApresDeces: number;
  };
  foyer: {
    perteAnnuelleAvantRetraite: number;
    perteAnnuelleApresRetraite: number;
  };
  capitalisation: {
    avantRetraite: {
      coefficientVictime: number;
      coefficientConjoint: number;
      montantCapitalise: number;
    };
    apresRetraite: {
      montantCapitalise: number;
    } & ({
      type: 'ageTheoriqueRetraite';
    } & PrejudiceFormPerteRevenusProcheCapitalisationAgeTheoriqueRetraite) &
      ({
        type: 'renteDifferee';
      } & PrejudiceFormPerteRevenusProcheCapitalisationRenteDifferee);
  };
}

const perteRevenusProcheFormType = ['UNE_PERIODE', 'DEUX_PERIODES'] as const;
export type PerteRevenusProcheFormType =
  (typeof perteRevenusProcheFormType)[number];
export type PrejudiceFormPerteRevenusProche = PrejudiceFormBase &
  PrejudiceFormCapitalisationOrRenteBase & {
    formType: 'UNE_PERIODE';
  } & PrejudiceFormPerteRevenusProcheUnePeriodeValues;
// | ({
//     formType: 'DEUX_PERIODES';
//   } & PrejudiceFormPerteRevenusProcheDeuxPeriodesValues)

export interface PrejudiceFormPerteRevenusProcheEnfantRow {
  victimeIndirecteId: string;
  ageFinSoutienFinancier: number | null;
  ageDernierArrerage: number | null;
  partIndividuelle?: number;
  partPerteAnnuelle: number;
  dureeIndemnisation: number;
  prejudiceEntreDecesEtLiquidation: number;
  coefficientCapitalisation?: number | null;
  montantCapitalise?: number;
  total: number;
  capitauxDeces: CapitauxDeces;
  totalApresCapitauxDeces: number;
}

export type PrejudiceFormValues =
  | PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel
  | PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal
  | PrejudiceFormValeur
  | PrejudiceFormCalendrierDepense
  | PrejudiceFormCalendrierDepenseCapitalisation
  | PrejudiceFormCalendrierAssistance
  | PrejudiceFormCalendrierAssistanceCapitalisation
  | PrejudiceFormListeProjection
  | OldPrejudiceFormPerteGainProfessionnelsActuel
  | NewPrejudiceFormPerteGainProfessionnelsActuel
  | OldPrejudiceFormPerteGainProfessionnelsFuturs
  | PrejudiceFormCalendrierValeur
  | PrejudiceFormPerteRevenusProche
  | PrejudiceFormPerteRevenusProcheVictimeNonDecedee
  | PrejudiceFormValeurVictimesIndirectes
  | RevenuActiviteAnnuelDeReference
  | FieldValues
  | PrejudiceFormMateriel;
/* Perte revenus proche victime non décédée */
export interface PrejudiceFormPerteRevenusProcheVictimeNonDecedee
  extends PrejudiceFormBase {
  perteRevenuPersonnel: RevenuProche;
  montantATPADeduire: RevenuProche;
  perteDroitRetraite: RevenuProche;
  surcroitActivite: RevenuProche;
  perteChanceAssistanceConjoint: RevenuProche;
  total: number;
  partResponsableTotal: number;
  partResponsabilite: number;
  coefficientsType?: Exclude<MonetaryErosionsCoefficientsType, 'mensuel'>;
}

/* Valeur victimes indirectes */
export interface PrejudiceFormValeurVictimesIndirectes
  extends PrejudiceFormBase {
  victimesIndirectes: PrejudiceFormValeurVictimesIndirectesRow[];
  montantTotal: number;
  partResponsabilite: number;
}

export interface PrejudiceFormValeurVictimesIndirectesRow {
  numerosPieces: NumeroPieceValues;
  victimeIndirecteId: string;
  montant: number;
  notes?: string;
}
