import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormValeurVictimesIndirectesRow,
} from '../../../types/prejudice.type';
import { VictimeIndirecte } from '../../../types/victimeIndirecte.type';
import { NormalTable } from '../../styled';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { fDate } from '../../../helpers/formatTime';
import { CalculsGlobal } from '../../../constants/calculs';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import { DeleteRounded } from '@mui/icons-material';
import { ComputedReadFieldForm } from '../../forms/ComputedReadFieldForm';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { useTranslation } from 'react-i18next';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { ResourcesButton } from 'src/components/resources/ResourcesButton';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { Procedure } from 'src/types/procedure.type';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaValeurVictimesIndirectes: yup.ObjectSchema<PrejudiceFormValeurVictimesIndirectes> =
  validationSchemaPrejudiceBase.shape({
    victimesIndirectes: yup
      .array()
      .defined()
      .of(
        yup.object({
          victimeIndirecteId: yup.string().defined(),
          montant: yup.number().required().min(0),
          numerosPieces: validationSchemaNumeroPieces,
          notes: yup.string().optional(),
        }),
      ),
    montantTotal: yup.number().optional().defined(),
    partResponsabilite: yup
      .number()
      .required()
      .min(0)
      .max(100)
      .transform((value) => value / 100),
  });
export const valeurVictimesIndirectesDefaultValues = ({
  procedure,
  values,
  multiplyPartResponsabilite,
}: {
  procedure: Procedure;
  values: PrejudiceFormValeurVictimesIndirectes | undefined;
  multiplyPartResponsabilite: boolean;
  victime: Victime;
}): PrejudiceFormValeurVictimesIndirectes => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  victimesIndirectes: values?.victimesIndirectes || [],
  montantTotal: values?.montantTotal || 0,
  partResponsabilite:
    (values?.partResponsabilite || values?.partResponsabilite === 0
      ? values?.partResponsabilite
      : procedure.partResponsabilite) * (multiplyPartResponsabilite ? 100 : 1),
});
interface Props
  extends PrejudiceFormProps<PrejudiceFormValeurVictimesIndirectes> {
  victimesIndirectes: VictimeIndirecte[];
}

export const FormValeurVictimesIndirectes: React.FC<Props> = ({
  values,
  victime,
  victimesIndirectes,
  prejudiceType,
  allNumerosPieces,
  procedure,
  ...props
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const useFormReturn = useForm<PrejudiceFormValeurVictimesIndirectes>({
    mode: 'onTouched',
    defaultValues: valeurVictimesIndirectesDefaultValues({
      procedure,
      values,
      multiplyPartResponsabilite: true,
      victime,
    }),
    resolver: yupResolver(validationSchemaValeurVictimesIndirectes),
  });
  const { control } = useFormReturn;

  const { fields, append, remove } = useFieldArray({
    name: 'victimesIndirectes',
    control,
  });
  const isVictimeIndirecteLieuDeVieColumnVisible = victimesIndirectes.some(
    (victimeIndirecte) => !!victimeIndirecte.lieuDeVie,
  );
  const isAgeAuDecesColumnVisible = Boolean(
    victime.dateDeces &&
      victimesIndirectes.some(
        (victimeIndirecte) => !!victimeIndirecte.dateNaissance,
      ),
  );
  const handleClickAddVictime = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAddVictime = () => {
    setAnchorEl(null);
  };
  const displayBaremeButton = Boolean(
    prejudiceType === 'PREJUDICE_AFFECTION_OU_MORAL',
  );

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormValeurVictimesIndirectes>
      {...props}
      victime={victime}
      prejudiceType={prejudiceType}
      procedure={procedure}
      victimesIndirectes={victimesIndirectes}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={2}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.introduction`,
              {
                defaultValue: '',
                context: victime.dateDeces ? 'deces' : 'blessure',
              },
            )}
          />
          <ComputedTextFieldForm
            control={control}
            name="partResponsabilite"
            label={t('partResponsabilite.label')}
            watchFields={[]}
            compute={() => procedure.partResponsabilite * 100}
            InputLabelProps={{
              shrink: true,
              sx: { color: 'info.light' },
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2, min: 0, max: 100 },
                suffix: '%',
              } as IMaskNumberProps,
              sx: {
                color: 'info.light',
                borderColor: 'info.light',
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'info.light',
                },
              },
            }}
            sx={{ minWidth: '268px' }}
          />
          <TableContainer component={Paper}>
            <NormalTable size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {t('numeroPiece.form.columnHeader')}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.nom.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lienVictime.columnHeader',
                    )}
                  </TableCell>
                  {isVictimeIndirecteLieuDeVieColumnVisible && (
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lieuDeVie.columnHeader',
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.dateNaissance.columnHeader',
                    )}
                  </TableCell>
                  {isAgeAuDecesColumnVisible && (
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.ageDeces.columnHeader',
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.montant.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.notes.columnHeader',
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => {
                  const victimeIndirecte = victimesIndirectes.find(
                    (victimeIndirecte) =>
                      victimeIndirecte._id === field.victimeIndirecteId,
                  );

                  if (victimeIndirecte) {
                    const ageDeces = victime.dateDeces
                      ? CalculsGlobal.getAgeOrZero(
                          victimeIndirecte.dateNaissance,
                          victime.dateDeces,
                        )
                      : null;
                    return (
                      <TableRow key={field.id}>
                        <TableCell align="center">
                          <FormNumeroPieceDialog
                            control={control}
                            name={`victimesIndirectes.${index}.numerosPieces`}
                            allOtherNumeroPieces={allNumerosPieces}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {t('victimeIndirecte.displayName', {
                            nom: victimeIndirecte.nom,
                            prenom: victimeIndirecte.prenom,
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {t(
                            `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`,
                          )}
                        </TableCell>
                        {isVictimeIndirecteLieuDeVieColumnVisible && (
                          <TableCell align="center">
                            {victimeIndirecte.lieuDeVie &&
                              t(
                                `victimeIndirecte.form.fields.lieuDeVie.options.${victimeIndirecte.lieuDeVie}`,
                              )}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {fDate(victimeIndirecte.dateNaissance)}
                        </TableCell>
                        {isAgeAuDecesColumnVisible && (
                          <TableCell align="center">
                            {ageDeces &&
                              t('forms.fields.age.value', {
                                count: ageDeces.age,
                                context: ageDeces.isExactlyZero
                                  ? 'exactly_zero'
                                  : undefined,
                              })}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`victimesIndirectes.${index}.montant`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2 },
                                suffix: '€',
                              } as IMaskNumberProps,
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`victimesIndirectes.${index}.notes`}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label={t('forms.table.deleteRow', {
                              index: index + 1,
                            })}
                            onClick={() => remove(index)}
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </NormalTable>
          </TableContainer>
          <ComputedReadFieldForm
            control={control}
            name="montantTotal"
            watchFields={['victimesIndirectes']}
            compute={(values) => {
              const victimesIndirectesRows: PrejudiceFormValeurVictimesIndirectesRow[] =
                values[0];

              return CalculsGlobal.sum(
                victimesIndirectesRows.map(
                  (victimeIndirecteRow) => victimeIndirecteRow.montant,
                ),
              );
            }}
          />

          <Stack direction="row" justifyContent="center">
            <Button
              onClick={handleClickAddVictime}
              disabled={fields.length === victimesIndirectes.length}
            >
              {t(
                'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.addRow',
              )}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleCloseAddVictime}
            >
              {victimesIndirectes
                .filter(
                  (victimeIndirecte) =>
                    fields.findIndex(
                      (field) =>
                        field.victimeIndirecteId === victimeIndirecte._id,
                    ) === -1,
                )
                .map((victimeIndirecte) => (
                  <MenuItem
                    key={victimeIndirecte._id}
                    onClick={() => {
                      append({
                        victimeIndirecteId: victimeIndirecte._id,
                        montant: 0,
                        numerosPieces: { rows: [] },
                      });
                      handleCloseAddVictime();
                    }}
                  >{`${victimeIndirecte.prenom} ${victimeIndirecte.nom} - ${t(
                    `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`,
                  )}`}</MenuItem>
                ))}
            </Menu>
          </Stack>

          <Stack direction="row-reverse">
            <ComputedPropsForm
              control={control}
              watchFields={['montantTotal', 'partResponsabilite']}
              compute={([montantTotal, partResponsabilite]) => {
                const returnedPartResponsabilite = partResponsabilite
                  ? partResponsabilite / 100
                  : procedure.partResponsabilite;
                return {
                  props: {
                    total: montantTotal,
                    totalPartResponsabilite:
                      Number(montantTotal) * returnedPartResponsabilite,
                    partResponsabilite: returnedPartResponsabilite,
                  },
                };
              }}
              render={({
                total,
                totalPartResponsabilite,
                partResponsabilite,
              }) => (
                <TotalDeficit
                  totalMontantTotal={total}
                  totalPartResponsabilite={totalPartResponsabilite}
                  displayPartResponsabilite
                  partResponsabilite={partResponsabilite}
                />
              )}
            />
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4}>
              {displayBaremeButton && (
                <ResourcesButton baremeType="Affection" isMultipleBaremes />
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    />
  );
};
