import {
  AlignmentType,
  BorderStyle,
  HeightRule,
  Paragraph,
  Table,
  TableOfContents,
  TableRow,
  WidthType,
} from 'docx';
import { default as i18n, default as i18next } from 'i18next';
import { flatMap } from 'lodash';
import { getSelectedPrejudicesTypesPerCategory } from 'src/helpers/prejudices/sortAndOrder';
import {
  getPrejudicesTotal,
  getPrejudiceTotalSubAmounts,
  getTotalValuesSum,
  PrejudiceTotalName,
  PrejudiceTotalValue,
} from 'src/helpers/prejudices/total';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceCategorie,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { fCurrency } from '../formatNumber';
import { isPrejudiceVictimeIndirecte } from '../prejudices/prejudice';
import { getEmptyLine, getTableCell, getTextRun } from './docxFunctions';

export const makePrejudicesTotalPrint = ({
  procedure,
  prejudicesTotalValues,
  victime,
  victimesIndirectes,
  prejudices,
  dateLiquidation,
  monetaryErosions,
  isVictimeIndirecteTable,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  dateLiquidation?: Date;
  monetaryErosions: MonetaryErosion[];
  isVictimeIndirecteTable?: boolean;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  const prejudicesTypesPerCategory = getSelectedPrejudicesTypesPerCategory({
    victime,
    procedure,
    victimesIndirectes,
    prejudices,
  });
  if (
    (!prejudicesTypesPerCategory.VICTIMES_INDIRECTES_PATRIMONIAUX &&
      !prejudicesTypesPerCategory.VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX &&
      isVictimeIndirecteTable) ||
    (!isVictimeIndirecteTable &&
      !Object.entries(prejudicesTypesPerCategory)
        .filter(([categorie, _]) => categorie !== 'VICTIMES_INDIRECTES')
        .some(([types]) => types.length > 0))
  ) {
    return [];
  }
  const totalNames: PrejudiceTotalName[] = isVictimeIndirecteTable
    ? ['indemniteProche', 'indemniteVictimeEchue', 'indemniteVictimeAEchoir']
    : [
        'indemniteVictimeEchue',
        'indemniteVictimeAEchoir',
        'indemniteTiersPayeurEchue',
        'indemniteTiersPayeurAEchoir',
      ];
  let table: Table;
  const totalWidth = 9000;
  const labelColumnWidth = Math.floor(totalWidth * 0.3);
  if (isVictimeIndirecteTable) {
    const totalValues = getPrejudicesTotal({
      victime,
      victimesIndirectes,
      procedure,
      prejudices: prejudices.filter((prejudice) =>
        isPrejudiceVictimeIndirecte(prejudice.type),
      ),
      dateLiquidation,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
    const totalSubAmountValues = getPrejudiceTotalSubAmounts(
      flatMap(Object.values(prejudicesTotalValues)),
    );
    const allSubAmountNames = Object.entries(prejudicesTotalValues).reduce(
      (accumulator, [_, values]) => {
        const subAmountNames = values.reduce((subAmounts, value) => {
          if (value.subAmounts) {
            subAmounts.push(
              ...value.subAmounts
                .map((subAmount) => subAmount.name)
                .filter(
                  (name) =>
                    !subAmounts.includes(name) && !accumulator.includes(name),
                ),
            );
          }
          return subAmounts;
        }, [] as string[]);
        return [...accumulator, ...subAmountNames];
      },
      [] as string[],
    );
    const totalColumns = allSubAmountNames.length + 3;
    const dataColumnWidth = Math.floor((totalWidth * 0.7) / (totalColumns - 1));

    table = new Table({
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      borders: {
        top: { style: BorderStyle.SINGLE, size: 1 },
        bottom: { style: BorderStyle.SINGLE, size: 1 },
        left: { style: BorderStyle.SINGLE, size: 1 },
        right: { style: BorderStyle.SINGLE, size: 1 },
        insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
        insideVertical: { style: BorderStyle.SINGLE, size: 1 },
      },
      columnWidths: [
        labelColumnWidth,
        ...Array(totalColumns - 1).fill(dataColumnWidth),
      ],
      layout: 'fixed',
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell(
              {
                children: [],
              },
              labelColumnWidth,
            ),
            ...allSubAmountNames.map((subAmountName) =>
              getTableCell(
                {
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: subAmountName,
                        bold: true,
                      }),
                    }),
                  ],
                },
                dataColumnWidth,
              ),
            ),
            getTableCell(
              {
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: i18next.t(
                        'print.prejudicesTotal.annexe.tiersPayeur.columnHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              dataColumnWidth,
            ),
            getTableCell(
              {
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: i18next.t(
                        'print.prejudicesTotal.annexe.total.columnHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              dataColumnWidth,
            ),
          ],
        }),
        ...(Object.keys(prejudicesTypesPerCategory) as PrejudiceCategorie[])
          .filter(
            (category) =>
              category === 'VICTIMES_INDIRECTES_PATRIMONIAUX' ||
              category === 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
          )
          .reduce(
            (accumulator: TableRow[], category) => [
              ...accumulator,
              new TableRow({
                children: [
                  getTableCell({
                    columnSpan: totalColumns,
                    children: [
                      new Paragraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...(prejudicesTypesPerCategory[category] ?? []).map(
                (prejudiceType) => {
                  const totalValues = prejudicesTotalValues[prejudiceType];
                  const total = getTotalValuesSum(totalValues || []);
                  const totalTiersPayeurs = totalValues?.find(
                    (value) => value.name === 'indemniteTiersPayeurEchue',
                  )?.amount;
                  return new TableRow({
                    height: {
                      value: 400,
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              children: getTextRun({
                                text: i18n.t(
                                  `prejudice.prejudicesTypes.${prejudiceType}.title`,
                                ),
                              }),
                            }),
                          ],
                        },
                        labelColumnWidth,
                      ),
                      ...allSubAmountNames.map((name) => {
                        const amount: number | undefined = totalValues?.reduce(
                          (accumulator: number | undefined, value) => {
                            if (value.subAmounts) {
                              const subAmount = value.subAmounts.find(
                                (subAmount) => subAmount.name === name,
                              )?.amount;
                              if (subAmount) {
                                return subAmount;
                              }
                            }
                            return accumulator;
                          },
                          undefined,
                        );

                        return getTableCell(
                          {
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: getTextRun({
                                  text:
                                    amount !== undefined
                                      ? fCurrency(amount)
                                      : '',
                                }),
                              }),
                            ],
                          },
                          dataColumnWidth,
                        );
                      }),
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text: totalTiersPayeurs
                                  ? fCurrency(totalTiersPayeurs)
                                  : '',
                              }),
                            }),
                          ],
                        },
                        dataColumnWidth,
                      ),
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  total !== undefined ? fCurrency(total) : '',
                              }),
                            }),
                          ],
                        },
                        dataColumnWidth,
                      ),
                    ],
                  });
                },
              ),
            ],
            [],
          ),
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          children: [
            getTableCell(
              {
                children: [
                  new Paragraph({
                    children: getTextRun({
                      text: i18next.t(
                        'print.prejudicesTotal.annexe.total.rowHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              labelColumnWidth,
            ),
            ...allSubAmountNames.map((name) =>
              getTableCell(
                {
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: fCurrency(totalSubAmountValues.proche[name] || 0),
                      }),
                    }),
                  ],
                },
                dataColumnWidth,
              ),
            ),
            getTableCell(
              {
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: fCurrency(totalValues.tiersPayeurs || 0),
                    }),
                  }),
                ],
              },
              dataColumnWidth,
            ),
            getTableCell(
              {
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: fCurrency(totalValues.total || 0),
                    }),
                  }),
                ],
              },
              dataColumnWidth,
            ),
          ],
        }),
      ],
    });
  } else {
    const totalColumns = 6;
    const dataColumnWidth = Math.floor((totalWidth * 0.7) / (totalColumns - 1));
    table = new Table({
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      borders: {
        top: { style: BorderStyle.SINGLE, size: 1 },
        bottom: { style: BorderStyle.SINGLE, size: 1 },
        left: { style: BorderStyle.SINGLE, size: 1 },
        right: { style: BorderStyle.SINGLE, size: 1 },
        insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
        insideVertical: { style: BorderStyle.SINGLE, size: 1 },
      },
      columnWidths: [
        labelColumnWidth,
        ...Array(totalColumns - 1).fill(dataColumnWidth),
      ],
      layout: 'fixed',
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell(
              {
                children: [],
              },
              labelColumnWidth,
            ),
            getTableCell(
              {
                columnSpan: 2,
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: i18n.t(
                        'print.prejudicesTotal.annexe.victime.columnHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              dataColumnWidth * 2,
            ),
            getTableCell(
              {
                columnSpan: 2,
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: i18n.t(
                        'print.prejudicesTotal.annexe.tiersPayeur.columnHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              dataColumnWidth * 2,
            ),
            getTableCell(
              {
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: i18n.t(
                        'print.prejudicesTotal.annexe.total.columnHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              dataColumnWidth,
            ),
          ],
        }),
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell(
              {
                children: [],
              },
              labelColumnWidth,
            ),
            ...['echus', 'aEchoir', 'echus', 'aEchoir'].map((headerType) =>
              getTableCell(
                {
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: i18n.t(
                          `print.prejudicesTotal.annexe.${headerType}.columnHeader`,
                        ),
                      }),
                    }),
                  ],
                },
                dataColumnWidth,
              ),
            ),
            getTableCell(
              {
                children: [],
              },
              dataColumnWidth,
            ),
          ],
        }),
        ...(Object.keys(prejudicesTypesPerCategory) as PrejudiceCategorie[])
          .filter(
            (category) =>
              category !== 'VICTIMES_INDIRECTES_PATRIMONIAUX' &&
              category !== 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
          )
          .reduce(
            (accumulator: TableRow[], category) => [
              ...accumulator,
              new TableRow({
                children: [
                  getTableCell({
                    columnSpan: totalColumns,
                    children: [
                      new Paragraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...(prejudicesTypesPerCategory[category] ?? []).map(
                (prejudiceType) => {
                  const totalValues = prejudicesTotalValues[prejudiceType];
                  const total = getTotalValuesSum(totalValues || []);

                  return new TableRow({
                    height: {
                      value: 400,
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              children: getTextRun({
                                text: i18n.t(
                                  `prejudice.prejudicesTypes.${prejudiceType}.title`,
                                ),
                              }),
                            }),
                          ],
                        },
                        labelColumnWidth,
                      ),
                      ...totalNames.map((name) => {
                        const amount = prejudicesTotalValues[
                          prejudiceType
                        ]?.find((value) => value.name === name)?.amount;

                        return getTableCell(
                          {
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: getTextRun({
                                  text:
                                    amount !== undefined
                                      ? fCurrency(amount)
                                      : '',
                                }),
                              }),
                            ],
                          },
                          dataColumnWidth,
                        );
                      }),
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  total !== undefined ? fCurrency(total) : '',
                              }),
                            }),
                          ],
                        },
                        dataColumnWidth,
                      ),
                    ],
                  });
                },
              ),
            ],
            [],
          ),
      ],
    });
  }
  const title = new Paragraph({
    children: [
      ...getTextRun({
        text: isVictimeIndirecteTable
          ? i18n.t('print.prejudicesTotal.victimeIndirecteTitle')
          : i18n.t('print.prejudicesTotal.victimeDirecteTitle'),
        bold: true,
        size: 20,
        break: 2,
      }),
      getEmptyLine(2),
    ],
  });
  return [title, table];
};
