import {
  AlignmentType,
  BorderStyle,
  HeightRule,
  Paragraph,
  Table,
  TableOfContents,
  TableRow,
  WidthType,
} from 'docx';
import i18n from 'i18next';
import { getPrejudicesPerCategoryPerDirectEntries } from 'src/helpers/prejudices/sortAndOrder';
import {
  getPrejudicesTotal,
  getPrejudiceTotalAmount,
  PrejudiceTotalValue,
} from 'src/helpers/prejudices/total';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice, PrejudiceType } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { fCurrency } from '../formatNumber';
import { getEmptyLine, getTableCell, getTextRun } from './docxFunctions';
import { simpleVerticalTablePrint } from './simpleVerticalTablePrint';

export const makePrejudicesMinimalTotalPrint = ({
  procedure,
  prejudicesTotalValues,
  victime,
  victimesIndirectes,
  prejudices,
  isVictimeIndirecteTable,
  monetaryErosions,
  dateLiquidation,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  isVictimeIndirecteTable?: boolean;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation?: Date;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  const prejudicesPerCategory = getPrejudicesPerCategoryPerDirectEntries({
    victime,
    procedure,
    victimesIndirectes,
    prejudices,
  })[isVictimeIndirecteTable ? 'INDIRECTE' : 'DIRECTE'];
  const prejudicesForPrint = prejudicesPerCategory
    .map(([, prejudices]) => prejudices)
    .flat();
  if (prejudicesPerCategory.length === 0) {
    return [];
  }
  let table: Table;
  if (isVictimeIndirecteTable) {
    table = simpleVerticalTablePrint([
      {
        title: i18n.t(
          'print.prejudicesTotal.minimal.table.victimeIndirecteHeader',
        ),
      },
      ...prejudicesPerCategory.reduce(
        (
          accumulator: Parameters<typeof simpleVerticalTablePrint>[0],
          [category, prejudices],
        ) => {
          if (prejudices.length === 0) {
            return accumulator;
          }
          return [
            ...accumulator,
            {
              title: i18n.t(`prejudice.categories.${category}`, ''),
            },
            ...prejudices.reduce(
              (
                accumulator: Parameters<typeof simpleVerticalTablePrint>[0],
                prejudice,
              ) => {
                const totalValue = prejudicesTotalValues[prejudice.type]?.[0];
                if (totalValue) {
                  accumulator.push({
                    label:
                      i18n.t(
                        `prejudice.prejudicesTypes.${prejudice.type}.title`,
                      ) || '',
                    value: fCurrency(totalValue.amount),
                  });
                }
                return accumulator;
              },
              [],
            ),
          ];
        },
        [],
      ),
    ]);
  } else {
    const totalValues = getPrejudicesTotal({
      victime,
      victimesIndirectes,
      procedure,
      prejudices: prejudicesForPrint,
      dateLiquidation,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
    const totalWidth = 9000;
    const headerLabels = [
      i18n.t('print.prejudicesTotal.minimal.table.columnHeaders.victime'),
      i18n.t('print.prejudicesTotal.minimal.table.columnHeaders.tiersPayeurs'),
      i18n.t('print.prejudicesTotal.minimal.table.columnHeaders.total'),
    ];
    const labelColumnWidth = Math.floor(totalWidth * 0.3);
    const dataColumnWidth = Math.floor(
      (totalWidth * 0.7) / headerLabels.length,
    );
    table = new Table({
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      borders: {
        top: { style: BorderStyle.SINGLE, size: 1 },
        bottom: { style: BorderStyle.SINGLE, size: 1 },
        left: { style: BorderStyle.SINGLE, size: 1 },
        right: { style: BorderStyle.SINGLE, size: 1 },
        insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
        insideVertical: { style: BorderStyle.SINGLE, size: 1 },
      },
      columnWidths: [
        labelColumnWidth,
        ...Array(headerLabels.length).fill(dataColumnWidth),
      ],
      layout: 'fixed',
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell(
              {
                children: [
                  new Paragraph({
                    children: getTextRun({
                      break: 1,
                    }),
                  }),
                ],
              },
              labelColumnWidth,
            ),
            ...headerLabels.map((name) =>
              getTableCell(
                {
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: name,
                        bold: true,
                      }),
                    }),
                  ],
                },
                dataColumnWidth,
              ),
            ),
          ],
        }),
        ...prejudicesPerCategory.reduce(
          (accumulator: TableRow[], [category, prejudices]) => {
            if (prejudices.length === 0) {
              return accumulator;
            }
            return [
              ...accumulator,
              new TableRow({
                children: [
                  getTableCell({
                    columnSpan: headerLabels.length + 1,
                    children: [
                      new Paragraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...prejudices.map((prejudice) => {
                const totalAmount = getPrejudiceTotalAmount({
                  prejudiceTotalValues:
                    prejudicesTotalValues[prejudice.type] || [],
                });

                return new TableRow({
                  height: {
                    value: 400,
                    rule: HeightRule.ATLEAST,
                  },
                  children: [
                    getTableCell(
                      {
                        children: [
                          new Paragraph({
                            children: getTextRun({
                              text: i18n.t(
                                `prejudice.prejudicesTypes.${prejudice.type}.title`,
                              ),
                            }),
                          }),
                        ],
                      },
                      labelColumnWidth,
                    ),
                    ...[
                      totalAmount.victime,
                      totalAmount.tiersPayeurs,
                      totalAmount.total,
                    ].map((amount) =>
                      getTableCell(
                        {
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  amount || amount === 0
                                    ? fCurrency(amount)
                                    : '',
                              }),
                            }),
                          ],
                        },
                        dataColumnWidth,
                      ),
                    ),
                  ],
                });
              }),
            ];
          },
          [],
        ),
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          children: [
            getTableCell(
              {
                children: [
                  new Paragraph({
                    children: getTextRun({
                      text: i18n.t(
                        'print.prejudicesTotal.minimal.table.totalTableRowHeader',
                      ),
                      bold: true,
                    }),
                  }),
                ],
              },
              labelColumnWidth,
            ),
            ...[
              totalValues.victime,
              totalValues.tiersPayeurs || 0,
              totalValues.victime + (totalValues.tiersPayeurs || 0),
            ].map((amount) =>
              getTableCell(
                {
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: fCurrency(amount),
                      }),
                    }),
                  ],
                },
                dataColumnWidth,
              ),
            ),
          ],
        }),
      ],
    });
  }
  const title = new Paragraph({
    children: [
      ...getTextRun({
        text: isVictimeIndirecteTable
          ? i18n.t('print.prejudicesTotal.minimal.victimeIndirecteTitle')
          : i18n.t('print.prejudicesTotal.minimal.victimeDirecteTitle'),
        bold: true,
        size: 20,
        break: 2,
      }),
      getEmptyLine(2),
    ],
  });
  return [title, table];
};
