import { Prejudice } from './prejudice.type';
import { VictimeIndirecte } from './victimeIndirecte.type';

export interface Procedure {
  _id: string;
  dateRedaction?: string | null;
  intitule: string;
  resumeAffaire: string;
  dateExpertise?: string | null;
  dateLiquidation?: string | null;
  commentaireExpertise: string;
  statut: ProcedureStatut;
  partResponsabilite: number;
  tiersPayeurs: string[];
  hospitalisations: ProcedureHospitalisation[];
  dommages: string[];
  answers: ProcedureQuestion[];
  victimesIndirectes: string[];
  jurisdiction?: Jurisdiction | null;
  procedureType?: ProcedureType | null;
  inTrash: boolean;
  isInitiated: boolean;
  dateConsolidation?: string | null;
  baremes?: ProcedureBaremes;
}

export const baremeIndemnisationValueChoices = [
  'minimum',
  'average',
  'maximum',
] as const;

export type BaremeIndemnisationValueChoice =
  (typeof baremeIndemnisationValueChoices)[number];

export const baremeRIIRoundChoices = ['down', 'up'] as const;

export type BaremeRIIRoundChoice = (typeof baremeRIIRoundChoices)[number];

export class ProcedureBaremes {
  baremeCapitalisationId?: string | null;
  baremeIndemnisationId?: string | null;
  baremeIndemnisationValueChoice?: BaremeIndemnisationValueChoice | null;
  baremeRIIId?: string | null;
  forfaitJourDFTP?: number | null;
  forfaitJourDFTT?: number | null;
  baremeRIIRoundAge?: BaremeRIIRoundChoice | null;
  baremeRIIRoundTauxDFP?: BaremeRIIRoundChoice | null;
}

export interface ProcedureHospitalisation {
  dateDebut: string | null;
  dateFin: string | null;
  jours: number;
  precisions: string;
  motifHospitalisation: string;
}

export type ProcedureQuestion =
  | 'ACCIDENT_DU_TRAVAIL'
  | 'ACCIDENT_DROIT_COMMUN'
  | 'INDEMNISATION_JUDICIAIRE'
  | 'INDEMNISATION_ADMINISTRATIVE';

export enum Jurisdiction {
  amicable = 'AMICABLE',
  judicial = 'JUDICIAL',
  administrative = 'ADMINISTRATIVE',
}

export enum ProcedureType {
  provision = 'PROVISION',
  liquidation = 'LIQUIDATION',
}

export enum ProcedureStatut {
  OUVERT = 'OUVERT',
  FERME = 'FERME',
}

/* DTO */

export type CreateProcedureDto = Omit<
  Procedure,
  '_id' | 'dommages' | 'victimesIndirectes'
>;

export type UpdateProcedureDto = Partial<
  Omit<CreateProcedureDto, 'dommages' | 'answers'>
>;

export type PopulatedProcedure = Procedure & {
  prejudices: Prejudice[];
  victimesIndirectes: VictimeIndirecte[];
};
