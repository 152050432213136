import { yupResolver } from '@hookform/resolvers/yup';
import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
} from 'src/constants/prejudice/validation';
import { dateString } from 'src/helpers/yup';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  CalculsFormCalendrierDeficitFonctionnelTemporaireTotal,
  CalculsGlobal,
} from '../../../constants/calculs';
import { fCurrency } from '../../../helpers/formatNumber';
import {
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { ComputedReadFieldForm } from '../../forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from '../../forms/ComputedTextFieldForm';
import { DatePickerForm } from '../../forms/DatePickerForm';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import { NormalTable } from '../../styled';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { TooltipTableHeader } from '../TooltipTableHeader';
export const validationSchemaCalendrierDeficitFonctionnelTemporaireTotal = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<CalculsFormCalendrierDeficitFonctionnelTemporaireTotal> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return yup.object({
    forfaitJour: yup.number().nullable(),
    rows: yup.array().of(
      yup.object({
        dateDebut: dateString()
          .nullable()
          .required()
          .minDate(undefined, minDate.message, minDate.date)
          .maxDate(undefined, maxDate.message, maxDate.date),
        dateFin: dateString()
          .nullable()
          .required()
          .minDate(
            'dateDebut',
            i18next.t('validation.prejudices.dates.minDateDebut') || '',
          )
          .maxDate(undefined, maxDate.message, maxDate.date),
        forfaitJour: yup.number().required(),
        totalJours: yup.number().required().min(0),
        montantTotal: yup.number().required().min(0),
        commentaires: yup.string(),

        numerosPieces: validationSchemaNumeroPieces,
        motifHospitalisation: yup.string().defined().nullable(),
      }),
    ),
  });
};
const getInitialValuesRow = (
  values: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
): PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow => ({
  dateDebut: null,
  dateFin: null,
  totalJours: 0,
  forfaitJour: values.forfaitJour || 0,
  montantTotal: 0,
  numerosPieces: { rows: [] },
  motifHospitalisation: '',
});
export const calendrierDeficitFonctionnelTemporaireTotalDefaultValues = ({
  values,
  procedure,
}: {
  values: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal | undefined;
  procedure: Procedure;
}): PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  forfaitJour:
    (values ? values?.forfaitJour : procedure.baremes?.forfaitJourDFTT) ?? 0,
  rows:
    sortBy(values?.rows || [], (row) => row.dateDebut)?.map((row) => ({
      ...getInitialValuesRow(
        values as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
      ),
      ...row,
    })) || [],
});
type Props =
  PrejudiceFormProps<PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal>;

export const FormCalendrierDeficitFonctionnelTemporaireTotal: React.FC<
  Props
> = ({
  values,
  procedure,
  victime,
  partResponsabilite,
  displayTotalPartResponsabilite,
  allNumerosPieces,
  ...props
}) => {
  const useFormReturn =
    useForm<PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal>({
      defaultValues: calendrierDeficitFonctionnelTemporaireTotalDefaultValues({
        values,
        procedure,
      }),
      resolver: yupResolver(
        validationSchemaCalendrierDeficitFonctionnelTemporaireTotal({
          procedure,
          victime,
          prejudiceType: props.prejudiceType,
        }),
      ),
    });

  const { fields, append, insert, remove } = useFieldArray({
    name: 'rows',
    control: useFormReturn.control,
  });
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow);
    handleMenuClose();
  };

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType: props.prejudiceType,
  });
  const { getValues, control } = useMemo(() => useFormReturn, [useFormReturn]);

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal>
      {...props}
      procedure={procedure}
      victime={victime}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <Typography
            align="center"
            variant="body2"
            sx={{
              fontWeight: 'bold',
              opacity: 0.45,
            }}
          >
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.periodesDFTTText',
            )}
          </Typography>
          <TextFieldForm
            sx={{ alignSelf: 'end', maxWidth: '200px' }}
            control={control}
            name="forfaitJour"
            label={t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.forfaitJour.label',
            )}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 1 },
                suffix: '€',
              } as IMaskNumberProps,
              sx: {
                color: 'info.light',
                borderColor: 'info.light',
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'info.light',
                },
              },
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <TableContainer component={Paper}>
            <NormalTable size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {t('numeroPiece.form.columnHeader')}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.dateDebut.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.dateFin.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.totalJours.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.motifHospitalisation.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TooltipTableHeader
                      headerLabel={t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.columnHeader',
                      )}
                      tooltipTitle={[
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.firstPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.firstPart.content',
                          ),
                        },
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.secondPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.secondPart.content',
                          ),
                        },
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.thirdPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.headerTooltip.thirdPart.content',
                          ),
                        },
                      ].map(({ title, content }, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 && <br />}
                          <Typography
                            fontWeight="bold"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {title}
                          </Typography>
                          <Typography whiteSpace="pre-line">
                            {content}
                          </Typography>
                        </React.Fragment>
                      ))}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.montantTotal.columnHeader',
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={field.id}>
                    <TableCell>
                      <FormNumeroPieceDialog
                        control={control}
                        name={`rows.${index}.numerosPieces`}
                        allOtherNumeroPieces={allNumerosPieces}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`rows.${index}.dateDebut`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`rows.${index}.dateFin`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ComputedTextFieldForm
                        control={control}
                        watchFields={[
                          `rows.${index}.dateDebut`,
                          `rows.${index}.dateFin`,
                        ]}
                        name={`rows.${index}.totalJours`}
                        compute={(values) => {
                          const [dateDebut, dateFin] = values;
                          if (!dateDebut || !dateFin) {
                            return 0;
                          }
                          return CalculsGlobal.getDays(dateDebut, dateFin);
                        }}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 0 },
                          } as IMaskNumberProps,
                        }}
                        editedFieldsName="editedFields"
                      />
                    </TableCell>
                    <TableCell>
                      <TextFieldForm
                        control={control}
                        name={`rows.${index}.motifHospitalisation`}
                        sx={{ minWidth: '200px' }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ComputedTextFieldForm
                        control={control}
                        watchFields={['forfaitJour']}
                        name={`rows.${index}.forfaitJour`}
                        compute={([forfaitJour]) => forfaitJour}
                        InputLabelProps={{
                          sx: { color: 'info.light' },
                        }}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 1 },
                            suffix: '€',
                          } as IMaskNumberProps,
                          sx: {
                            color: 'info.light',
                            borderColor: 'info.light',
                            ' .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'info.light',
                            },
                          },
                        }}
                        editedFieldsName="editedFields"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ComputedReadFieldForm
                        control={control}
                        name={`rows.${index}.montantTotal`}
                        watchFields={[
                          `rows.${index}.totalJours`,
                          `rows.${index}.forfaitJour`,
                        ]}
                        compute={(values) => {
                          const [totalJours, forfaitJour] = values.map(Number);

                          return CalculsFormCalendrierDeficitFonctionnelTemporaireTotal.montantTotal(
                            {
                              totalJours: totalJours || 0,
                              forfaitJour: forfaitJour || 0,
                            },
                          );
                        }}
                        render={(value: any) => <>{fCurrency(value)}</>}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={handleMenuOpen(index)}>
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuIndex === index}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleDelete(index)}>
                          {t('forms.table.deleteRow', { index: index + 1 })}
                        </MenuItem>
                        <MenuItem onClick={() => duplicateRow(index)}>
                          {t('forms.table.duplicateRow', { index: index + 1 })}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </NormalTable>
          </TableContainer>
          <Stack direction="row-reverse">
            <ComputedPropsForm
              control={control}
              watchFields={['rows']}
              compute={(values) => {
                const rows: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow[] =
                  values[0];
                const totaux =
                  CalculsFormCalendrierDeficitFonctionnelTemporaireTotal.total(
                    rows,
                  );

                return {
                  props: {
                    totalMontantTotal: totaux,
                    ...(displayTotalPartResponsabilite && partResponsabilite
                      ? { totalPartResponsabilite: totaux * partResponsabilite }
                      : {}),
                  },
                };
              }}
              render={({ totalMontantTotal, totalPartResponsabilite }) => (
                <TotalDeficit
                  totalMontantTotal={totalMontantTotal}
                  displayPartResponsabilite={displayTotalPartResponsabilite}
                  totalPartResponsabilite={totalPartResponsabilite}
                  partResponsabilite={partResponsabilite}
                />
              )}
            />
          </Stack>
          <Button
            sx={{ alignSelf: 'center' }}
            onClick={() => {
              const sortedValues: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow[] =
                getValues().rows.sort((a, b) => {
                  if (a.dateDebut && b.dateDebut) {
                    return (
                      new Date(a.dateDebut).getTime() -
                      new Date(b.dateDebut).getTime()
                    );
                  }
                  return 0;
                });

              const newRow: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow =
                getInitialValuesRow(getValues());

              append({
                ...newRow,
                dateDebut:
                  sortedValues[sortedValues.length - 1]?.dateFin || null,
              });
            }}
          >
            {t('forms.table.addRow')}
          </Button>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
