import { AlignmentType, BorderStyle, HeightRule, Table, WidthType } from 'docx';
import {
  getHeaderTableCell,
  getTableRow,
  getValueTableCell,
} from './docxFunctions';

export const simpleHorizontalTablePrint = (
  headerLabels: string[],
  rows: {
    rowLabel: string;
    columns: string[];
  }[],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  const totalWidth = 9000;
  const labelColumnWidth = Math.floor(totalWidth * 0.3);
  const dataColumnWidth = Math.floor((totalWidth * 0.7) / headerLabels.length);

  return new Table({
    alignment: alignment ?? AlignmentType.START,
    width: {
      size: totalWidth,
      type: WidthType.DXA,
    },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1 },
      bottom: { style: BorderStyle.SINGLE, size: 1 },
      left: { style: BorderStyle.SINGLE, size: 1 },
      right: { style: BorderStyle.SINGLE, size: 1 },
      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
    },
    columnWidths: Array(headerLabels.length).fill(dataColumnWidth),
    layout: 'fixed',
    rows: [
      getTableRow({
        children: headerLabels.map((label, index) =>
          getHeaderTableCell(
            label,
            index === 0 ? labelColumnWidth : dataColumnWidth,
            {
              alignment:
                index === 0 ? AlignmentType.START : AlignmentType.CENTER,
            },
          ),
        ),
      }),
      ...rows.map((row) =>
        getTableRow({
          children: [
            getHeaderTableCell(row.rowLabel, labelColumnWidth),
            ...row.columns.map((column) =>
              getValueTableCell(column, dataColumnWidth),
            ),
          ],
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
        }),
      ),
    ],
  });
};

export const simpleTablePrint = (
  headerLabels: string[],
  rowLabels: string[],
  rows: string[][],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  const totalWidth = 9000;
  const labelColumnWidth = Math.floor(totalWidth * 0.3);
  const dataColumnWidth = Math.floor((totalWidth * 0.7) / headerLabels.length);

  return new Table({
    alignment: alignment ?? AlignmentType.START,
    width: {
      size: totalWidth,
      type: WidthType.DXA,
    },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1 },
      bottom: { style: BorderStyle.SINGLE, size: 1 },
      left: { style: BorderStyle.SINGLE, size: 1 },
      right: { style: BorderStyle.SINGLE, size: 1 },
      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
    },
    columnWidths: [
      labelColumnWidth,
      ...Array(headerLabels.length).fill(dataColumnWidth),
    ],
    layout: 'fixed',
    rows: [
      getTableRow({
        children: [
          getHeaderTableCell('', labelColumnWidth, {
            alignment: AlignmentType.START,
          }),
          ...headerLabels.map((label) =>
            getHeaderTableCell(label, dataColumnWidth, {
              alignment: AlignmentType.CENTER,
            }),
          ),
        ],
      }),
      ...rowLabels.map((rowLabel, rowIndex) =>
        getTableRow({
          children: [
            getHeaderTableCell(rowLabel, labelColumnWidth, {
              alignment: AlignmentType.START,
            }),
            ...(rows[rowIndex] ?? []).map((column) =>
              getValueTableCell(column, dataColumnWidth),
            ),
          ],
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
        }),
      ),
    ],
  });
};
