import { Bareme, BaremeIndemnisationValues } from 'src/types/bareme.type';
import { PrejudiceFormBase } from 'src/types/prejudice.type';

export const prejudiceBaseDefaultValues = ({
  values,
}: {
  values: PrejudiceFormBase | undefined;
}): PrejudiceFormBase => ({
  notes: values?.notes || '',
  chiffrage: values?.chiffrage || 'en_cours',
  formVersion: values?.formVersion || 0,
  editedFields: values?.editedFields || [],
});
export type BaremeDefaultMontant = Bareme & {
  values: BaremeIndemnisationValues[];
};
export const getDefaultMontant = (
  bareme?: BaremeDefaultMontant,
  degree?: number | null,
  baremeValueChoice?: string,
) => {
  if (!bareme || !degree || !baremeValueChoice || !bareme.values[degree - 1]) {
    return 0;
  }
  const degreeIndex = degree - 1;
  const currentValues = bareme.values[degreeIndex];

  const valueKeyMap: Record<string, keyof BaremeIndemnisationValues> = {
    minimum: 'fromValue',
    average: 'average',
    maximum: 'toValue',
  };
  const valueKey = baremeValueChoice
    ? valueKeyMap[baremeValueChoice] || null
    : null;

  if (!valueKey) {
    return 0;
  }
  switch (bareme.source) {
    case 'Mornet': {
      if (degreeIndex === 7) {
        return currentValues?.fromValue || 0;
      }
      if (valueKey === 'average') {
        const { fromValue = 0, toValue = 0 } =
          currentValues as BaremeIndemnisationValues;
        return (fromValue + toValue) / 2;
      }
      return currentValues?.[valueKey] || 0;
    }
    case 'FGTI':
      return currentValues?.value || 0;
    case 'ONIAM':
      return currentValues?.[valueKey] || 0;
    default:
      return 0;
  }
};
