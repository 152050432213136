import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';

interface DegreeSelectFieldProps {
  control: Control<any>;
  name: string;
  onChange?: (value: number) => void;
}

const DegreeSelectField: React.FC<DegreeSelectFieldProps> = ({
  control,
  name,
  onChange,
}) => {
  const { t } = useTranslation();

  const degreeOptions = Array.from({ length: 14 }, (_, i) => {
    const value = (i + 2) / 2;
    return { value, label: value.toString() };
  });

  degreeOptions.push({
    value: 8,
    label: t('bareme.type.IndemnisationValues.degree.exception'),
  });

  return (
    <SelectFieldForm
      name={name}
      control={control}
      label={t('bareme.type.IndemnisationValues.degree.label')}
      options={degreeOptions}
      afterOnChange={(value) => {
        if (onChange) onChange(Number(value));
      }}
      noSelectionText={t('common.select')}
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default DegreeSelectField;
