import { yupResolver } from '@hookform/resolvers/yup';
import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { AutocompleteForm } from 'src/components/forms/AutocompleteForm';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { dateString } from 'src/helpers/yup';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  CalculsFormCalendrierDeficitFonctionnelTemporairePartiel,
  CalculsGlobal,
} from '../../../constants/calculs';
import { fCurrency } from '../../../helpers/formatNumber';
import {
  commentairesOptions,
  DFTPRowType,
  DFTPRowTypes,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { ComputedReadFieldForm } from '../../forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from '../../forms/ComputedTextFieldForm';
import { DatePickerForm } from '../../forms/DatePickerForm';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import { LayoutTable, NormalTable } from '../../styled';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { TooltipTableHeader } from '../TooltipTableHeader';

export const validationSchemaCalendrierDeficitFonctionnelTemporairePartiel = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase.shape({
    forfaitJour: yup.number().nullable().defined(),
    rows: yup
      .array()
      .defined()
      .of(
        yup.object({
          dateDebut: dateString()
            .nullable()
            .minDate(undefined, minDate.message, minDate.date)
            .maxDate(undefined, maxDate.message, maxDate.date)
            .defined()
            .when(['type'], ([type], schema) =>
              type === 'dftp' ? schema.required() : schema,
            ),
          dateFin: dateString()
            .nullable()
            .minDate(
              'dateDebut',
              i18next.t('validation.prejudices.dates.minDateDebut') || '',
            )
            .maxDate(undefined, maxDate.message, maxDate.date)
            .defined()
            .when(['type'], ([type], schema) =>
              type === 'dftp' ? schema.required() : schema,
            ),
          joursPeriode: yup.number().required(),
          joursHospitalisationADeduire: yup
            .number()
            .optional()
            .when(['type'], ([type], schema) => {
              if (type === 'dftp') {
                return schema.defined();
              } else {
                return schema;
              }
            }),
          deduireJoursHospitalisation: yup
            .boolean()
            .when(['type'], ([type], schema) => {
              if (type === 'dftp') {
                return schema.required();
              } else {
                return schema;
              }
            }),
          totalJours: yup.number().required(),
          percentage: yup
            .number()
            .nullable()
            .min(0)
            .max(100)
            .when(['type'], ([type], schema) => {
              if (type === 'dftp') {
                return schema.required();
              } else {
                return schema;
              }
            }),
          forfaitJour: yup.number().when(['type'], ([type], schema) => {
            if (type === 'dftp') {
              return schema.required();
            } else {
              return schema;
            }
          }),
          montantTotal: yup.number().required().min(0),
          commentaires: yup
            .string()
            .optional()
            .nullable()
            .when(['type'], ([type], schema) => {
              if (type === 'autreComposante') {
                return schema.required();
              } else {
                return schema;
              }
            }),
          numerosPieces: validationSchemaNumeroPieces,
          type: yup.string().defined().oneOf(DFTPRowTypes),
        }),
      ),

    total: yup.number().required(),
  });
};
const getInitialValuesRow = (
  values: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  type: DFTPRowType,
): PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow => ({
  dateDebut: null,
  dateFin: null,
  joursHospitalisationADeduire: 0,
  deduireJoursHospitalisation: false,
  joursPeriode: 0,
  totalJours: 0,
  percentage: null,
  forfaitJour: values.forfaitJour || 0,
  montantTotal: 0,
  commentaires: '',
  numerosPieces: { rows: [] },
  type,
});
export const calendrierDeficitFonctionnelTemporairePartielDefaultValues = ({
  values,
  procedure,
}: {
  values:
    | PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel
    | undefined;
  procedure: Procedure;
}): PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel => ({
  ...prejudiceBaseDefaultValues({
    values,
  }),
  notes: values?.notes || '',
  forfaitJour:
    (values ? values?.forfaitJour : procedure.baremes?.forfaitJourDFTP) ?? 0,
  rows:
    sortBy(values?.rows, (row) => row.dateDebut)?.map((row) => {
      const defaultRowType: DFTPRowType = row.commentaires
        ? 'autreComposante'
        : 'dftp';
      return {
        ...getInitialValuesRow(
          values as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
          row.type ?? defaultRowType,
        ),
        ...row,
      };
    }) || [],
  total: values?.total || 0,
});
type Props =
  PrejudiceFormProps<PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel>;

export const FormCalendrierDeficitFonctionnelTemporairePartiel: React.FC<
  Props
> = ({
  values,
  procedure,
  victime,
  displayTotalPartResponsabilite,
  partResponsabilite,
  allNumerosPieces,
  ...props
}) => {
  const useFormReturn =
    useForm<PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel>({
      defaultValues: calendrierDeficitFonctionnelTemporairePartielDefaultValues(
        {
          values,
          procedure,
        },
      ),
      resolver: yupResolver(
        validationSchemaCalendrierDeficitFonctionnelTemporairePartiel({
          procedure,
          victime,
          prejudiceType: props.prejudiceType,
        }),
      ),
    });
  const { control, getValues } = useMemo(() => useFormReturn, [useFormReturn]);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow);
    handleMenuClose();
  };
  const { fields, append, insert, remove } = useFieldArray({
    name: 'rows',
    control,
  });
  const forfaitJourColor = 'info.light';
  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType: props.prejudiceType,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel>
      {...props}
      {...useFormReturn}
      procedure={procedure}
      victime={victime}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <TextFieldForm
            sx={{ alignSelf: 'end', maxWidth: '200px' }}
            control={control}
            name="forfaitJour"
            label="Base journalière"
            InputLabelProps={{
              shrink: true,
              sx: { color: forfaitJourColor },
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 1 },
                suffix: '€',
              } as IMaskNumberProps,
              sx: {
                color: forfaitJourColor,
                borderColor: forfaitJourColor,
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: forfaitJourColor,
                },
              },
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <TableContainer component={Paper}>
            <NormalTable size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {t('numeroPiece.form.columnHeader')}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.dateDebut.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.dateFin.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.joursPeriode.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TooltipTableHeader
                      headerLabel={t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.joursHospitalisationADeduire.columnHeader',
                      )}
                      tooltipTitle={
                        t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.joursHospitalisationADeduire.headerTooltip',
                        ) || ''
                      }
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: '102px' }}>
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.deduireJoursHospitalisation.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.totalJours.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TooltipTableHeader
                      headerLabel={t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.percentage.columnHeader',
                      )}
                      tooltipTitle={
                        t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.percentage.headerTooltip',
                        ) || ''
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TooltipTableHeader
                      headerLabel={t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.columnHeader',
                      )}
                      tooltipTitle={[
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.firstPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.firstPart.content',
                          ),
                        },
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.secondPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.secondPart.content',
                          ),
                        },
                        {
                          title: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.thirdPart.title',
                          ),
                          content: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.headerTooltip.thirdPart.content',
                          ),
                        },
                      ].map(({ title, content }, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 && <br />}
                          <Typography
                            fontWeight="bold"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {title}
                          </Typography>
                          <Typography whiteSpace="pre-line">
                            {content}
                          </Typography>
                        </React.Fragment>
                      ))}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.montantTotal.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.commentaires.columnHeader',
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={field.id}>
                    <TableCell>
                      <FormNumeroPieceDialog
                        control={control}
                        name={`rows.${index}.numerosPieces`}
                        allOtherNumeroPieces={allNumerosPieces}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`rows.${index}.dateDebut`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`rows.${index}.dateFin`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ComputedTextFieldForm
                        control={control}
                        watchFields={[
                          `rows.${index}.dateDebut`,
                          `rows.${index}.dateFin`,
                        ]}
                        name={`rows.${index}.joursPeriode`}
                        compute={(values) => {
                          const [dateDebut, dateFin] = values;
                          if (dateDebut && dateFin) {
                            return CalculsGlobal.getDays(dateDebut, dateFin);
                          }
                          return 0;
                        }}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 0 },
                          } as IMaskNumberProps,
                        }}
                        editedFieldsName="editedFields"
                      />
                    </TableCell>

                    {field.type === 'autreComposante' ? (
                      <>
                        <TableCell colSpan={2} />
                        <TableCell align="right">
                          <ComputedReadFieldForm
                            control={control}
                            name={`rows.${index}.totalJours`}
                            watchFields={[`rows.${index}.joursPeriode`]}
                            compute={([joursPeriode]) =>
                              CalculsGlobal.max([0, joursPeriode])
                            }
                          />
                        </TableCell>
                        <TableCell colSpan={2} />
                        <TableCell>
                          <TextFieldForm
                            control={control}
                            name={`rows.${index}.montantTotal`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2 },
                                suffix: '€',
                              } as IMaskNumberProps,
                            }}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">
                          <ComputedTextFieldForm
                            control={control}
                            name={`rows.${index}.joursHospitalisationADeduire`}
                            watchFields={[
                              `rows.${index}.dateDebut`,
                              `rows.${index}.dateFin`,
                            ]}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 0 },
                              } as IMaskNumberProps,
                            }}
                            compute={([dateDebut, dateFin]) =>
                              CalculsGlobal.getJoursHospitalisationsADeduire(
                                dateDebut,
                                dateFin,
                                procedure.hospitalisations,
                              )
                            }
                            editedFieldsName="editedFields"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CheckboxFormField
                            control={
                              control as Control<PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel>
                            }
                            name={`rows.${index}.deduireJoursHospitalisation`}
                          />
                        </TableCell>
                        <TableCell>
                          <ComputedTextFieldForm
                            control={control}
                            name={`rows.${index}.totalJours`}
                            watchFields={[
                              `rows.${index}.joursHospitalisationADeduire`,
                              `rows.${index}.joursPeriode`,
                              `rows.${index}.deduireJoursHospitalisation`,
                            ]}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 0 },
                              } as IMaskNumberProps,
                            }}
                            compute={([
                              joursHospitalisationADeduire,
                              joursPeriode,
                              deduireJoursHospitalisation,
                            ]) => {
                              const totalJours = deduireJoursHospitalisation
                                ? joursPeriode -
                                  (joursHospitalisationADeduire || 0)
                                : joursPeriode;
                              return CalculsGlobal.max([0, totalJours]);
                            }}
                            editedFieldsName="editedFields"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextFieldForm
                            control={control}
                            name={`rows.${index}.percentage`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 0, max: 100 },
                                nullable: true,
                                suffix: '%',
                              } as IMaskNumberProps,
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ComputedTextFieldForm
                            control={control}
                            watchFields={['forfaitJour']}
                            name={`rows.${index}.forfaitJour`}
                            compute={([forfaitJour]) => forfaitJour}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 1 },
                                suffix: '€',
                              } as IMaskNumberProps,
                              sx: {
                                color: forfaitJourColor,
                                borderColor: forfaitJourColor,
                                ' .MuiOutlinedInput-notchedOutline': {
                                  borderColor: forfaitJourColor,
                                },
                              },
                            }}
                            editedFieldsName="editedFields"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ComputedReadFieldForm
                            control={control}
                            name={`rows.${index}.montantTotal`}
                            watchFields={[
                              `rows.${index}.totalJours`,
                              `rows.${index}.percentage`,
                              `rows.${index}.forfaitJour`,
                            ]}
                            compute={(values) => {
                              const [totalJours, percentage, forfaitJour] =
                                values.map(Number);

                              return CalculsFormCalendrierDeficitFonctionnelTemporairePartiel.montantTotal(
                                {
                                  totalJours: totalJours || 0,
                                  forfaitJour: forfaitJour || 0,
                                  percentage: percentage || 0,
                                },
                              );
                            }}
                            render={(value: any) => <>{fCurrency(value)}</>}
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {field.type === 'autreComposante' ? (
                        <AutocompleteForm
                          control={control}
                          name={`rows.${index}.commentaires`}
                          sx={{ minWidth: '200px' }}
                          options={commentairesOptions}
                          freeSolo
                        />
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={handleMenuOpen(index)}>
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuIndex === index}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleDelete(index)}>
                          {t('forms.table.deleteRow', { index: index + 1 })}
                        </MenuItem>
                        <MenuItem onClick={() => duplicateRow(index)}>
                          {t('forms.table.duplicateRow', { index: index + 1 })}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </NormalTable>
          </TableContainer>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              sx={{ alignSelf: 'center' }}
              onClick={() => {
                append(getInitialValuesRow(getValues(), 'dftp'));
              }}
            >
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.addRow.row',
              )}
            </Button>
            <Button
              sx={{ alignSelf: 'center' }}
              onClick={() => {
                append(getInitialValuesRow(getValues(), 'autreComposante'));
              }}
            >
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.addRow.commentaires',
              )}
            </Button>
          </Stack>
          <Stack direction="row-reverse">
            <CalculsBox>
              <LayoutTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" colSpan={4}>
                      {t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.total.tableRowHeader',
                      )}
                    </TableCell>
                    <TableCell align="right" colSpan={4}>
                      <ComputedTextFieldForm
                        control={control}
                        name="total"
                        watchFields={['rows']}
                        compute={([rows]) =>
                          CalculsFormCalendrierDeficitFonctionnelTemporairePartiel.total(
                            rows,
                          ) || 0
                        }
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                        editedFieldsName="editedFields"
                      />
                    </TableCell>
                  </TableRow>
                  {displayTotalPartResponsabilite &&
                    (partResponsabilite || partResponsabilite === 0) && (
                      <ComputedPropsForm
                        control={control}
                        watchFields={['total']}
                        compute={([total]) => {
                          return {
                            props: {
                              totalPartResponsabilite:
                                total * partResponsabilite,
                            },
                          };
                        }}
                        render={({ totalPartResponsabilite }) => (
                          <TableRow>
                            <TableCell align="right" colSpan={4}>
                              {t('partResponsabilite.tableRowHeader', {
                                partResponsabilite: fPartResponsabilite(
                                  partResponsabilite * 100,
                                ),
                              })}
                            </TableCell>
                            <TableCell align="right" colSpan={4}>
                              {fCurrency(totalPartResponsabilite || 0)}
                            </TableCell>
                          </TableRow>
                        )}
                      />
                    )}
                </TableHead>
              </LayoutTable>
            </CalculsBox>
          </Stack>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
