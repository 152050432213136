import { calendrierAssistanceDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierAssistance';
import { calendrierAssistanceCapitalisationDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierAssistanceCapitalisation';
import { calendrierDeficitFonctionnelTemporairePartielDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierDeficitFonctionnelTemporairePartiel';
import { calendrierDeficitFonctionnelTemporaireTotalDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierDeficitFonctionnelTemporaireTotal';
import { calendrierDepenseDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierDepense';
import { calendrierDepenseCapitalisationDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierDepenseCapitalisation';
import { calendrierValeurDefaultValues } from 'src/components/client/prejudiceFormTypes/FormCalendrierValeur';
import { listeProjectionDefaultValues } from 'src/components/client/prejudiceFormTypes/FormListeProjection';
import { materielDefaultValues } from 'src/components/client/prejudiceFormTypes/FormMateriel';
import { perteRevenusProcheVictimeDecedeeDefaultValues } from 'src/components/client/prejudiceFormTypes/PertesRevenusProche/FormPertesRevenusProcheVictimeDecedee';
import { perteRevenusProcheVictimeNonDecedeeDefaultValues } from 'src/components/client/prejudiceFormTypes/FormPertesRevenusProcheVictimeNonDecedee';
import { valeurDefaultValues } from 'src/components/client/prejudiceFormTypes/FormValeur';
import { valeurVictimesIndirectesDefaultValues } from 'src/components/client/prejudiceFormTypes/FormValeurVictimesIndirectes';
import { newPGPADefaultValues } from 'src/components/client/prejudiceFormTypes/NewFormPerteGainsProfessionnelsActuel';
import { newPGPFDefaultValues } from 'src/components/client/prejudiceFormTypes/NewFormPerteGainsProfessionnelsFuturs';
import { oldPGPADefaultValues } from 'src/components/client/prejudiceFormTypes/OldFormPerteGainsProfessionnelsActuel';
import { oldPGPFDefaultValues } from 'src/components/client/prejudiceFormTypes/OldFormPerteGainsProfessionnelsFuturs';
import { Bareme } from 'src/types/bareme.type';
import { Constant } from 'src/types/constant.type';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  Prejudice,
  PrejudiceFormBase,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationLegacy,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormListeProjection,
  PrejudiceFormMateriel,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceFormScolaire,
  PrejudiceFormValeur,
  PrejudiceFormValeurVictimesIndirectes,
  UpdateMultiplePrejudicesDto,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { pgpfSaisieDirecteDefaultValues } from 'src/components/client/prejudiceFormTypes/FormPerteGainsProfessionnelsFutursSaisieDirecte';
import { PGPASaisieDirecteDefaultValues } from 'src/components/client/prejudiceFormTypes/FormPerteGainsProfessionnelsActuelSaisieDirecte';
import i18next from 'i18next';
import { isPrejudiceVictimeIndirecte } from './prejudice';
import { getScolaireDefaultValues } from 'src/components/client/prejudiceFormTypes/FormScolaire';
import { migrateDSF, shouldMigrateDSF } from './migrateDSF';

export const getPrejudicesThatShouldBeAutomaticallyUpdated = (
  appConstants: Constant,
  prejudices: Prejudice[],
) =>
  prejudices.filter((prejudice) => {
    const formType = prejudice.formType;
    const appConstantsFormVersion =
      appConstants.prejudicesFormsVersions?.[formType]?.currentVersion;
    const formVersion = (prejudice.formData as PrejudiceFormBase).formVersion;
    if (!formVersion && !appConstantsFormVersion) {
      return false;
    }

    if (!formVersion && appConstantsFormVersion) {
      return true;
    }

    if (
      formVersion &&
      appConstantsFormVersion &&
      appConstantsFormVersion > formVersion
    ) {
      return true;
    }
    return false;
  });

export const getPrejudiceDefaultValues = ({
  prejudice,
  procedure,
  victime,
  baremes,
  victimesIndirectes,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudice: Prejudice;
  baremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
}) => {
  switch (prejudice.formType) {
    case 'CALENDRIER_DEPENSE':
      return calendrierDepenseDefaultValues({
        procedure,
        victime,
        hidePeriode: prejudice.type === 'FRAIS_OBSEQUES',
        values: prejudice.formData as PrejudiceFormCalendrierDepense,
        multiplyPartResponsabilite: false,
        isVictimeIndirecte: isPrejudiceVictimeIndirecte(prejudice.type),
      });
    case 'VALEUR':
      return valeurDefaultValues({
        procedurePartResponsabilite: procedure.partResponsabilite,
        values: prejudice.formData as PrejudiceFormValeur,
        multiplyPartResponsabilite: false,
        prejudiceType: prejudice.type,
      });
    case 'CALENDRIER_DEPENSE_CAPITALISATION': {
      const formData = prejudice.formData as
        | PrejudiceFormCalendrierDepenseCapitalisation
        | PrejudiceFormCalendrierDepenseCapitalisationLegacy;
      // This is necessary to migrate the legacy dsf form
      if (shouldMigrateDSF(formData)) {
        return migrateDSF({
          formData:
            formData as PrejudiceFormCalendrierDepenseCapitalisationLegacy,
          dateLiquidation: procedure.dateLiquidation
            ? new Date(procedure.dateLiquidation)
            : undefined,
        });
      }

      return calendrierDepenseCapitalisationDefaultValues({
        procedure,
        victime,
        values:
          prejudice.formData as PrejudiceFormCalendrierDepenseCapitalisation,
      });
    }
    case 'CALENDRIER_ASSISTANCE':
      return calendrierAssistanceDefaultValues({
        procedure,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        values: prejudice.formData as PrejudiceFormCalendrierAssistance,
      });
    case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
      return calendrierAssistanceCapitalisationDefaultValues({
        procedure,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        values:
          prejudice.formData as PrejudiceFormCalendrierAssistanceCapitalisation,
      });
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
      return calendrierDeficitFonctionnelTemporaireTotalDefaultValues({
        values:
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,

        procedure,
      });
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
      return calendrierDeficitFonctionnelTemporairePartielDefaultValues({
        values:
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,

        procedure,
      });
    case 'LISTE_PROJECTION':
      return listeProjectionDefaultValues({
        victime,
        dateConsolidation: procedure.dateConsolidation
          ? new Date(procedure.dateConsolidation)
          : undefined,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        values: prejudice.formData as PrejudiceFormListeProjection,
        baremes,
        procedure,
        prejudiceType: prejudice.type,
      });
    case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL': {
      const isOldPGPA = !!(
        prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsActuel
      ).dateFin;
      const formType = (
        prejudice.formData as
          | OldPrejudiceFormPerteGainProfessionnelsActuel
          | NewPrejudiceFormPerteGainProfessionnelsActuel
          | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
      ).formType;
      if (formType === 'DECLARATION_FISCALES' || isOldPGPA) {
        return oldPGPADefaultValues({
          procedure,
          values:
            prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsActuel,
        });
      } else if (formType === 'SAISIE_DIRECTE') {
        return PGPASaisieDirecteDefaultValues({
          values:
            prejudice.formData as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
        });
      } else {
        return newPGPADefaultValues({
          values:
            prejudice.formData as NewPrejudiceFormPerteGainProfessionnelsActuel,
        });
      }
    }
    case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
      const isOldPGPF = !!(
        prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsActuel
      ).dateFin;
      const formType = (
        prejudice.formData as
          | OldPrejudiceFormPerteGainProfessionnelsFuturs
          | NewPrejudiceFormPerteGainProfessionnelsFuturs
          | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
      ).formType;
      if (formType === 'DECLARATION_FISCALES' || isOldPGPF) {
        return oldPGPFDefaultValues({
          procedure,
          values:
            prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsFuturs,
        });
      } else if (formType === 'SAISIE_DIRECTE') {
        return pgpfSaisieDirecteDefaultValues({
          values:
            prejudice.formData as PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
          procedure,
        });
      } else {
        return newPGPFDefaultValues({
          procedure,
          values:
            prejudice.formData as NewPrejudiceFormPerteGainProfessionnelsFuturs,
        });
      }
    }

    case 'CALENDRIER_VALEURS':
      return calendrierValeurDefaultValues({
        values: prejudice.formData as PrejudiceFormCalendrierValeur,
      });
    case 'MATERIEL':
      return materielDefaultValues({
        procedure,
        values: prejudice.formData as PrejudiceFormMateriel,
        multiplyPartResponsabilite: false,
      });
    case 'PERTES_REVENUS_PROCHE':
      return !!victime.dateDeces
        ? perteRevenusProcheVictimeDecedeeDefaultValues({
            procedure,
            values: prejudice.formData as PrejudiceFormPerteRevenusProche,
            victimesIndirectes: victimesIndirectes,
          })
        : perteRevenusProcheVictimeNonDecedeeDefaultValues({
            procedure,
            values:
              prejudice.formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
          });
    case 'VALEUR_VICTIMES_INDIRECTES':
      return (
        valeurVictimesIndirectesDefaultValues({
          procedure,
          values: prejudice.formData as PrejudiceFormValeurVictimesIndirectes,
          multiplyPartResponsabilite: false,
          victime,
        }) || null
      );
    case 'SCOLAIRE':
      return getScolaireDefaultValues({
        values: prejudice.formData as PrejudiceFormScolaire,
      });
    default:
      return null;
  }
};

export const getAutomaticallyUpdatedPrejudicesDto = ({
  procedure,
  victime,
  baremes,
  victimesIndirectes,
  prejudices,
  appConstants,
}: {
  procedure: Procedure;
  victime: Victime;
  baremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
  appConstants: Constant;
}): UpdateMultiplePrejudicesDto => {
  try {
    const prejudicesThatShouldBeAutomaticallyUpdated =
      getPrejudicesThatShouldBeAutomaticallyUpdated(appConstants, prejudices);
    const updatedPrejudices = prejudicesThatShouldBeAutomaticallyUpdated
      .map((prejudice) => {
        try {
          const prejudiceDefaultValues = getPrejudiceDefaultValues({
            procedure,
            victime,
            prejudice,
            baremes,
            victimesIndirectes,
          });
          return {
            ...prejudice,
            formData: {
              ...prejudiceDefaultValues,
              formVersion:
                appConstants.prejudicesFormsVersions?.[prejudice.formType]
                  .currentVersion ?? undefined,
            },
          };
        } catch (e) {
          console.error(
            i18next.t('prejudice.automaticUpdate.error', {
              error: e,
              prejudice: JSON.stringify(prejudice),
              prejudiceName: i18next.t(
                `prejudice.prejudicesTypes.${prejudice.type}.title`,
              ),
            }),
          );
          return null;
        }
      })
      .filter(
        (prejudice: Prejudice | null): prejudice is Prejudice =>
          prejudice !== null,
      ) as Prejudice[];
    return {
      prejudices: updatedPrejudices.map((prejudice) => ({
        _id: prejudice._id,
        formData: prejudice.formData,
      })),
    };
  } catch (e) {
    console.error(
      i18next.t('prejudice.automaticUpdate.error', {
        error: e,
        prejudices: JSON.stringify(prejudices),
      }),
    );
    return {
      prejudices: [],
    };
  }
};
