import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';

import { MoreHoriz } from '@mui/icons-material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  BaremeDefaultMontant,
  getDefaultMontant,
} from 'src/constants/prejudice/defaultValues';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
} from 'src/constants/prejudice/validation';
import { dateString } from 'src/helpers/yup';
import { Bareme } from 'src/types/bareme.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { CalculsGlobal } from '../../../../constants/calculs';
import {
  ListeProjectionMaladie,
  ListeProjectionMaladieRow,
  NumeroPieceValuesRow,
  PrejudiceFormListeProjection,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { CalculsBox } from '../../../basic/CalculsBox';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { NormalTable } from '../../../styled';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import DegreeSelectField from './DegreeSelectField';

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  allNumerosPieces: NumeroPieceValuesRow[];
  baremeValueChoice?: string;
  baremeIndemnisation?: Bareme;
}

export const validationSchemaPrejudiceMaladie = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<ListeProjectionMaladie> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return yup.object({
    rows: yup
      .array()
      .defined()
      .of(
        yup.object({
          maladie: yup.string().optional().defined(),
          dateDebut: dateString()
            .nullable()
            .optional()
            .defined()
            .minDate(undefined, minDate.message, minDate.date)
            .maxDate(undefined, maxDate.message, maxDate.date),
          dateFin: dateString()
            .nullable()
            .optional()
            .defined()
            .minDate(
              'dateDebut',
              i18next.t('validation.prejudices.dates.minDateDebut') || '',
            )
            .maxDate(undefined, maxDate.message, maxDate.date),
          jours: yup.number().required().min(0).integer(),
          degre: yup.number().when([], (_, schema) => {
            if (prejudiceType === 'PREJUDICE_SEXUEL') {
              return schema.nullable().optional();
            }
            return schema.optional().nullable().min(0).max(8);
          }),
          montant: yup.number().required().min(0),
          numerosPieces: validationSchemaNumeroPieces,
        }),
      ),
  });
};
const initialValuesRow: ListeProjectionMaladieRow = {
  maladie: '',
  dateDebut: null,
  dateFin: null,
  jours: 0,
  montant: 0,
  numerosPieces: { rows: [] },
};
export const getInitialValuesPrejudiceMaladie = (
  values: ListeProjectionMaladie | undefined,
): ListeProjectionMaladie => ({
  rows:
    values?.rows?.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) ?? [],
});

export const PrejudiceMaladie: React.FC<Props> = ({
  control,
  procedure,
  victime,
  prejudiceType,
  allNumerosPieces,
  baremeValueChoice,
  baremeIndemnisation,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as ListeProjectionMaladieRow);
    handleMenuClose();
  };

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({ procedure, victime, prejudiceType });
  const displayDegre = prejudiceType !== 'PREJUDICE_SEXUEL';
  const displayDate =
    prejudiceType !== 'PREJUDICE_SEXUEL' &&
    prejudiceType !== 'PREJUDICE_ESTHETIQUE_PERMANENT';
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.maladie.columnHeader',
                )}
              </TableCell>
              {displayDate && (
                <>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.dateDebut.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.dateFin.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.jours.columnHeader',
                    )}
                  </TableCell>
                </>
              )}
              {displayDegre && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.degre.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`prejudiceValues.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.maladie`}
                    sx={{ width: '100%' }}
                  />
                </TableCell>
                {displayDate && (
                  <>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`prejudiceValues.rows.${index}.dateDebut`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickerForm
                        control={control}
                        name={`prejudiceValues.rows.${index}.dateFin`}
                        TextFieldProps={{
                          sx: {
                            minWidth: 155,
                            width: 155,
                          },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </TableCell>
                    <TableCell>
                      <ComputedTextFieldForm
                        control={control}
                        name={`prejudiceValues.rows.${index}.jours`}
                        watchFields={[
                          `prejudiceValues.rows.${index}.dateDebut`,
                          `prejudiceValues.rows.${index}.dateFin`,
                        ]}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 0 },
                          } as IMaskNumberProps,
                        }}
                        compute={(values) => {
                          const [dateDebut, dateFin] = values;

                          if (dateDebut && dateFin) {
                            return CalculsGlobal.getDays(dateDebut, dateFin);
                          }
                          return 0;
                        }}
                        editedFieldsName="editedFields"
                      />
                    </TableCell>
                  </>
                )}
                {displayDegre && (
                  <TableCell>
                    <DegreeSelectField
                      control={control}
                      name={`prejudiceValues.rows.${index}.degre`}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <ComputedTextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.montant`}
                    watchFields={[
                      `prejudiceValues.rows.${index}.degre`,
                      'baremeIndemnisation',
                      'baremeValueChoice',
                    ]}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    compute={(value) => {
                      const degreValue = value[0] !== undefined ? value[0] : 0;
                      const montant =
                        getDefaultMontant(
                          baremeIndemnisation as BaremeDefaultMontant,
                          degreValue,
                          baremeValueChoice,
                        ) ?? 0;
                      return montant;
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <CalculsBox
        sx={{
          alignSelf: 'flex-end',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'background.default',
          },
        }}
      >
        <Stack direction="column" spacing={2}>
          <ComputedTextFieldForm
            control={control}
            name="montantTotal"
            watchFields={['prejudiceValues.rows']}
            compute={([rows]) => {
              return CalculsGlobal.sommeMontantRows(rows);
            }}
            label={t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.listeProjection.montantTotal.label',
            )}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            InputLabelProps={{ shrink: true }}
            editedFieldsName="editedFields"
          />
        </Stack>
      </CalculsBox>
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow);
        }}
      >
        {t('forms.table.addRow')}
      </Button>
    </>
  );
};
