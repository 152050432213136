import React from 'react';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
} from '../../../../types/prejudice.type';
import { fCurrency } from '../../../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import { CalculsFormCalendrier } from 'src/constants/calculs/calculsFormCalendrier';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { CalculsFormCalendrierDepense } from 'src/constants/calculs';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { HiddenFieldForm } from 'src/components/forms/HiddenFieldForm';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';

interface Props {
  control:
    | Control<PrejudiceFormCalendrierDepenseCapitalisation>
    | Control<PrejudiceFormCalendrierDepense>;
  tiersPayeurs: string[];
  isCalculCapitalisation: boolean | undefined;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  hideDepenseRecurrente: boolean | undefined;
  hidePeriode: boolean | undefined;
  isVictimeIndirecte: boolean | undefined;
  shouldNotDisplayCapitalisation: boolean;
}

export const TableDepensesFooter: React.FC<Props> = ({
  control,
  tiersPayeurs,
  isCalculCapitalisation,
  dateLiquidation,
  monetaryErosions,
  hideDepenseRecurrente,
  isVictimeIndirecte,
  hidePeriode,
  shouldNotDisplayCapitalisation,
}) => {
  const { t } = useTranslation();
  const totalColSpanOffset =
    (isVictimeIndirecte ? 1 : 0) +
    (!hideDepenseRecurrente ? 2 : 0) +
    (!hidePeriode ? 2 : 0) +
    (isCalculCapitalisation && !shouldNotDisplayCapitalisation ? 3 : 1);
  return (
    <TableFooter
      sx={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 4,
      }}
    >
      <TableRow>
        <TableCell />
        <TableCell
          sx={{
            position: 'sticky',
            left: 0,
            zIndex: 3,
            backgroundColor: 'white',
          }}
        />
        <TableCell colSpan={totalColSpanOffset} />
        <TableCell align="right" colSpan={2}>
          {isCalculCapitalisation
            ? t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.totalRow.rowHeader.echu',
              )
            : t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.totalRow.rowHeader.noAEchoir',
              )}
        </TableCell>
        {!!tiersPayeurs.length && <TableCell colSpan={tiersPayeurs.length} />}
        <ComputedPropsForm
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          watchFields={['rows', 'revalorisationCoefficientsType']}
          compute={([rows, revalorisationCoefficientsType]) => {
            const totauxDepenses = CalculsFormCalendrier.totauxDepenses({
              rows,
              tiersPayeurs,
              dateLiquidation,
              monetaryErosions,
              isCalculCapitalisation: isCalculCapitalisation || false,
              isVictimesIndirectes: isVictimeIndirecte || false,
              revalorisationCoefficientsType,
            });
            return {
              props: {
                totauxDepenses,
              },
            };
          }}
          render={({ totauxDepenses }) => (
            <>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                {fCurrency(totauxDepenses.montantTotal)}
              </TableCell>
              {tiersPayeurs.map((tierPayeur) => (
                <TableCell
                  key={tierPayeur}
                  align="right"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {fCurrency(
                    totauxDepenses.priseEnChargeTiersPayeurs[tierPayeur] || 0,
                  )}
                </TableCell>
              ))}
              <TableCell />
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                {fCurrency(totauxDepenses.resteACharge)}
              </TableCell>
              {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
                <>
                  <TableCell colSpan={5 + (tiersPayeurs.length || 0)} />
                </>
              ) : null}
            </>
          )}
        />
        <TableCell colSpan={2} />
        {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
          <ComputedReadFieldForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            name="capitalisationTiersPayeurs.montantCapitalise"
            watchFields={['capitalisationTiersPayeurs.parTiersPayeur']}
            compute={([parTiersPayeur]) =>
              CalculsFormCalendrierDepense.totalTiersPayeursCapitalisation(
                parTiersPayeur,
              )
            }
          />
        ) : null}
      </TableRow>
      {isCalculCapitalisation ? (
        <TableRow>
          <TableCell />
          <TableCell
            sx={{
              position: 'sticky',
              left: 0,
              zIndex: 3,
              backgroundColor: 'white',
            }}
          />
          <TableCell colSpan={totalColSpanOffset} />
          <TableCell align="right" colSpan={2}>
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.totalRow.rowHeader.aEchoir',
            )}
          </TableCell>
          {!!tiersPayeurs.length && <TableCell colSpan={tiersPayeurs.length} />}
          <ComputedPropsForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            watchFields={['rows', 'revalorisationCoefficientsType']}
            compute={([rows, revalorisationCoefficientsType]) => {
              const totauxDepensesAEchoir =
                CalculsFormCalendrier.totauxDepensesAEchoir({
                  rows,
                  tiersPayeurs,
                  dateLiquidation,
                  monetaryErosions,
                  isCalculCapitalisation: isCalculCapitalisation || false,
                  isVictimesIndirectes: isVictimeIndirecte || false,
                  revalorisationCoefficientsType,
                });

              const totauxDepensesTiersPayeursCapitalisation =
                CalculsFormCalendrierDepense.totauxDepensesTiersPayeursCapitalisation(
                  {
                    rows,
                    dateLiquidation,
                  },
                );
              const totauxDepensesCapitalisation =
                CalculsFormCalendrierDepense.totauxDepensesCapitalisation({
                  rows,
                  dateLiquidation,
                });
              return {
                props: {
                  totauxDepensesAEchoir,
                  totauxDepensesTiersPayeursCapitalisation,
                  totauxDepensesCapitalisation,
                },
              };
            }}
            valuesToSetAfterCompute={
              {
                ['capitalisation.montantCapitalise']:
                  'totauxDepensesCapitalisation.montantCapitalise',
              } as const
            }
            render={({
              totauxDepensesAEchoir,
              totauxDepensesCapitalisation,
              totauxDepensesTiersPayeursCapitalisation,
            }) => (
              <>
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                  {fCurrency(totauxDepensesAEchoir.montantTotal)}
                </TableCell>
                {tiersPayeurs.map((tierPayeur) => (
                  <TableCell
                    key={tierPayeur}
                    align="right"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {fCurrency(
                      totauxDepensesAEchoir.priseEnChargeTiersPayeurs[
                        tierPayeur
                      ] || 0,
                    )}
                  </TableCell>
                ))}
                <TableCell />
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                  {fCurrency(totauxDepensesAEchoir.resteACharge)}
                </TableCell>
                {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
                  <>
                    <TableCell colSpan={5} />
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      {fCurrency(
                        totauxDepensesCapitalisation?.montantCapitalise || 0,
                      )}
                    </TableCell>
                    {tiersPayeurs.map((tiersPayeur, tiersPayeurIndex) => (
                      <TableCell
                        key={tiersPayeurIndex}
                        align="right"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        <HiddenFieldForm
                          control={
                            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                          }
                          name={`capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.tiersPayeur`}
                          value={tiersPayeur}
                        />
                        <ComputedTextFieldForm
                          control={
                            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                          }
                          name={`capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.montantCapitalise`}
                          editedFieldsName="editedFields"
                          watchFields={[]}
                          customValues={[
                            totauxDepensesTiersPayeursCapitalisation
                              ?.parTiersPayeur[tiersPayeur],
                          ]}
                          compute={(_, customValues) => customValues?.[0] || 0}
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              suffix: '€',
                              numberMask: { scale: 2, signed: true },
                            } as IMaskNumberProps,
                          }}
                          sx={{ whiteSpace: 'nowrap' }}
                        />
                      </TableCell>
                    ))}
                  </>
                ) : null}
              </>
            )}
          />
          <TableCell colSpan={2} />
          {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
            <ComputedReadFieldForm
              control={
                control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
              }
              name="capitalisationTiersPayeurs.montantCapitalise"
              watchFields={['capitalisationTiersPayeurs.parTiersPayeur']}
              compute={([parTiersPayeur]) =>
                CalculsFormCalendrierDepense.totalTiersPayeursCapitalisation(
                  parTiersPayeur,
                )
              }
            />
          ) : null}
        </TableRow>
      ) : null}
    </TableFooter>
  );
};
